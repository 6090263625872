<template>
  <ion-app>
  <ion-split-pane content-id="main">
    <!--  the side menu  -->
    <ion-menu  v-if="enabled" side="start" content-id="main">
      <ion-header>
      </ion-header>
      <ion-content>

       <!-- <ion-fab v-if="!isLoggedIn" style="zoom: 0.8;" horizontal="end" vertical="top" slot="fixed">
        <ion-fab-button color="primary">
          <ion-icon :md="addOutline" :ios="addOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="bottom">
          <ion-fab-button color="success" :data-desc="$t('register')" @click="openRegister()">
            <ion-icon :ios="createOutline" :md="createOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
        </ion-fab>    -->  

        <ion-list id="start-list">
          <ion-list-header v-if="logoHeader && logoHeader != ''" class="ion-margin-bottom" @click="$store.dispatch('setSelectedIndex', 0); router.push('/home')">
            <ion-img style="heigth: 220px; width: 220px;" :src="logoHeader" />
          </ion-list-header>
          <ion-list-header class="ion-margin-bottom" @click="$store.dispatch('setSelectedIndex', 0); router.push('/home')">
            <ion-img style="heigth: 60px; width: 60px;" :src="logo" /><br>
            <ion-img style="margin-left: 5px; width:160px;" :src="logocio" />
          </ion-list-header>

          <ion-item v-if="UserData">
              <ion-avatar v-if="UserData.imageUrl" @contextmenu.prevent="" style="margin-right: 5px">
                <img :src="UserData.imageUrl">
              </ion-avatar>
                <ion-icon v-if="!UserData.imageUrl" slot="start" :ios="personSharp" :md="personSharp"></ion-icon>
                <ion-label>
                  <h2>{{ UserData.title }} {{ UserData.firstName }} {{ UserData.lastName }}</h2>
                </ion-label>
          </ion-item>
            <ion-menu-toggle auto-hide="false">
              <ion-item v-if="clubInfo">
                    <ion-label style="margin-left: 5px" class="ion-text-wrap">
                      <h2>{{ clubInfo.name }}</h2>
                      <p>{{ clubInfo.nameShort }}</p>
                    </ion-label>
              </ion-item>
<!--              <ion-item v-if="clubInfo && isPlatform('capacitor')">
                <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="goClubSelection()">
                  <ion-icon style="zoom: 1.0; margin-left: 1px;" color="primary" :md="chevronBackOutline" :ios="chevronBackOutline"></ion-icon>
                  <ion-label color="primary" style="margin-left: 5px" >{{ $t('clubSelection') }}</ion-label>
                </ion-button>     
              </ion-item>-->
            </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages.filter(f => f.showInNav != false)" :key="i">
            <ion-item v-if="p.requiresLogin == false || (isLoggedIn)" @click="$store.dispatch('setSelectedIndex', i); if (p.title == 'news') batchNum=0; if (p.title == 'newsperson') batchNumPerson=0; if (isPlatform('capacitor')) PushNotifications.removeAllDeliveredNotifications();" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
              <ion-icon slot="start" :ios="p.icon" :md="p.icon"></ion-icon>
              <ion-label class="clickable">{{ $t(p.title) }}</ion-label><ion-badge v-if="p.title=='news' && batchNum > 0" color="danger">1</ion-badge><ion-badge v-if="p.title=='newsperson' && batchNumPerson > 0" color="danger">1</ion-badge><ion-badge v-if="p.title=='friends' && UserData.friendBatchNum > 0" color="danger">{{ UserData.friendBatchNum }}</ion-badge>
            </ion-item>
          </ion-menu-toggle>

          <ion-item v-if="isLoggedIn" lines="none" @click="checkLogout()">
            <ion-icon slot="start" :ios="logOutOutline" :md="logOutOutline"></ion-icon>
            <ion-label class="clickable">{{ $t('logout') }}</ion-label>
          </ion-item>

          <ion-item v-if="!isLoggedIn" lines="none" @click="login()">
            <ion-icon slot="start" :ios="logInOutline" :md="logInOutline"></ion-icon>
            <ion-label class="clickable">{{ $t('login') }}</ion-label>
          </ion-item>

          <ion-item v-if="!isLoggedIn && clubInfo && clubInfo.canRegister" lines="none" @click="openRegister()">
            <ion-icon slot="start" :ios="createOutline" :md="createOutline"></ion-icon>
            <ion-label class="clickable">{{ $t('register') }}</ion-label>
          </ion-item>

          <ion-item lines="none" v-if="clubInfo && isPlatform('capacitor')" @click="goClubSelection()">
            <ion-icon slot="start" color="primary" :md="chevronBackOutline" :ios="chevronBackOutline"></ion-icon>
            <ion-label color="primary" class="clickable" >{{ $t('clubSelection') }}</ion-label>
           </ion-item>

          <ion-item style="font-size: 12px;" lines="none" @click="impressum()">
            <ion-icon slot="start" :ios="informationCircleOutline" :md="informationCircleOutline"></ion-icon>
            <ion-label class="clickable">{{ $t('imprint') }} </ion-label>
          </ion-item>

<!--           <ion-item style="font-size: 12px;" lines="none" v-if="isPlatform('capacitor')" @click="aboutApp()">
            <ion-icon slot="start" :ios="removeOutline" :md="removeOutline"></ion-icon>
            <ion-label class="clickable">{{ $t('aboutApp') }} </ion-label>
          </ion-item>-->

          <ion-item lines="none" v-if="!isLoggedIn && !isPlatform('capacitor')">
            <ion-button fill="none" @click="openUrl('https://apps.apple.com/us/app/club-in-one-4u/id1616499691')">
            <ion-img class="ion-no-padding ion-no-margin" style="width: 80px; align: left;" :src="apple" />
            </ion-button>
            <ion-button fill="none" @click="openUrl('https://play.google.com/store/apps/details?id=de.smartup.CLUBINONE4U', '_system')">
              <ion-img class="ion-no-padding ion-no-margin" style="width: 80px; align: left;" :src="google" />
            </ion-button>
          </ion-item>

        </ion-list>
      </ion-content>
    </ion-menu>

    <!-- the main content -->
     <div class="ion-page" id="main">
      <ion-header  v-if="enableNav">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
              <ion-button v-if="!isMainPath" @click="goBack()">
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="chevronBackOutline" :ios="chevronBackOutline"></ion-icon>
              </ion-button>     
              <ion-button v-if="$route && $route.name && $route.name.startsWith('teetime_')" @click="openHelpTeetime()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>     
              <ion-button v-if="$route && $route.name && $route.path.startsWith('competitions')" @click="openHelpTournament()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>          
              <ion-button v-if="$route && $route.name && $route.path.startsWith('/courses')" @click="openHelpCourses()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="$route && $route.name && $route.path.startsWith('/competitions')" @click="openHelpCompetitions()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="$route && $route.name && $route.path.startsWith('/events')" @click="openHelpEvent()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="$route && $route.name && $route.name.startsWith('pro_')" @click="openHelpPro()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>     
              <ion-button v-if="$route && $route.name && $route.name.startsWith('range_')" @click="openHelpRange()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>     
              <ion-button v-if="$route && $route.name && $route.name.startsWith('wellnesse_')" @click="openHelpWellness()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>     
              <ion-button v-if="$route && $route.name && $route.name.startsWith('trackman_')" @click="openHelpTrackman()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>     
              <ion-button v-if="$route && $route.name && $route.name.startsWith('indoor_')" @click="openHelpIndoor()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>     
              <ion-button v-if="$route && $route.name && $route.path.startsWith('/friends')" @click="openHelpFriends()" >
                <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
              </ion-button>     
            </ion-buttons>
            <ion-title slot="start" v-if="appPages && appPages.length > 0 && appPages[selectedIndex]">
              {{ $t(appPages[selectedIndex].title) }}
          </ion-title>

          <ion-button  v-if="!isLoggedIn && enabled" slot="end" fill="none" @click="login()">
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="logInOutline" :ios="logInOutline"></ion-icon>
          </ion-button>
          <ion-button  v-if="isLoggedIn && enabled" slot="end" fill="none" @click="checkLogout()">
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0; margin-left: 1px;' : 'zoom: 1.2; margin-left: 1px;'" color="primary" :md="logOutOutline" :ios="logOutOutline"></ion-icon>
          </ion-button>

          <country-flag @click="openLang(true, $event)" style="margin-right: 15px;" v-if="lang" slot="end" :country="lang == 'en' ? 'gb' : lang" size='normal'/>
          <ion-popover :is-open="isopenLang" :event="event" reference="event" :translucent="true" @didDismiss="openLang(false)">
            <ion-content>
              <ion-list>
                <ion-item @click="setLanguage('de')">
                  <country-flag slot="start" style="margin-right: 5px;" country='de' size='small'/>
                  <div>{{ $t('de') }}</div>
                </ion-item>
                <ion-item @click="setLanguage('en')">
                  <country-flag slot="start" style="margin-right: 5px;" country='gb' size='small'/>
                  <div>{{ $t('en') }}</div>
                </ion-item>
                <ion-item @click="setLanguage('it')">
                  <country-flag slot="start" style="margin-right: 5px;" country='it' size='small'/>
                  <div>{{ $t('it') }}</div>
                </ion-item>
                <ion-item @click="setLanguage('fr')">
                  <country-flag slot="start" style="margin-right: 5px;" country='fr' size='small'/>
                  <div>{{ $t('fr') }}</div>
                </ion-item>
                <ion-item @click="setLanguage('nl')">
                  <country-flag slot="start" style="margin-right: 5px;" country='nl' size='small'/>
                  <div>{{ $t('nl') }}</div>
                </ion-item>
                <ion-item @click="setLanguage('cz')">
                  <country-flag slot="start" style="margin-right: 5px;" country='cz' size='small'/>
                  <div>{{ $t('cz') }}</div>
                </ion-item>
                <ion-item @click="setLanguage('es')">
                  <country-flag slot="start" style="margin-right: 5px;" country='es' size='small'/>
                  <div>{{ $t('es') }}</div>
                </ion-item>
                <ion-item @click="setLanguage('cn')">
                  <country-flag slot="start" style="margin-right: 5px;" country='cn' size='small'/>
                  <div>{{ $t('cn') }}</div>
                </ion-item>
              </ion-list>
            </ion-content>
          </ion-popover>
        </ion-toolbar>
      </ion-header>
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ion-split-pane>
  </ion-app>
</template>

<script>
import { toastController, modalController, IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, alertController,
IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonApp, IonContent, IonIcon, IonItem, 
IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, IonSelect, 
IonSelectOption, IonAvatar, IonFabList, IonFabButton, IonFab, IonBadge, IonPopover, menuController } from '@ionic/vue';
import { defineComponent, ref, computed, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { arrowForwardCircleOutline, removeOutline, logOutOutline, logInOutline, personOutline,
  personSharp, settingsOutline, settingsSharp, medalOutline, medalSharp, statsChartOutline, informationCircleOutline,
  statsChartSharp, peopleOutline, peopleSharp, calendarSharp, calendarOutline, golfOutline,
  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, newspaperOutline,
  heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, 
  trashSharp, warningOutline, warningSharp,businessOutline, eyeOutline, caretUp, informationOutline,
  addOutline, personAddOutline, createOutline, fastFoodOutline, globeOutline, homeOutline,
  helpOutline, helpCircleOutline, helpBuoyOutline, schoolOutline, peopleCircleOutline, reorderFourOutline,
  chevronBackOutline, idCardOutline, addCircleOutline, cardOutline, chevronDownOutline, telescopeOutline, 
  storefrontOutline, giftOutline } from 'ionicons/icons'
import { useQuery, useResult, useMutation} from '@vue/apollo-composable'
import LoginDialog from './views/Login'
import HelpBookingTeetimeDialog from './views/HelpBookingTeetimeDialog'
import HelpBookingProDialog from './views/HelpBookingProDialog'
import AddCreditDialog from './views/dialogs/AddCreditDialog'
import HelpBookingRangeDialog from './views/HelpBookingRangeDialog'
import HelpBookingWellnessDialog from './views/HelpBookingWellnessDialog'
import HelpBookingTrackmanDialog from './views/HelpBookingTrackmanDialog'
import HelpBookingIndoorDialog from './views/HelpBookingIndoorDialog'
import YesDialog from './views/dialogs/YesDialog'
import HelpFriendsDialog from './views/HelpFriendsDialog'
import HelpTournamentDialog from './views/HelpTournamentDialog'
import HelpCoursesDialog from './views/HelpCoursesDialog'
import HelpEventDialog from './views/HelpEventDialog'
import ImprintDialog from './views/dialogs/ImprintDialog'
import HelpCompetitionDialog from './views/HelpCompetitionDialog'
import logoutUserMutationFile from './graphql/logoutUser.mutation.gql'
import sendTokenMutationFile from './graphql/sendToken.mutation.gql'
import removeTokenMutationFile from './graphql/removeToken.mutation.gql'
import checkInMutationFile from './graphql/checkIn.mutation.gql'
import Storage from "./plugins/storage.js";
import RegisterDialog from './views/Register'
import AboutApp from './views/dialogs/AboutApp'
import ShowInfoScreenDialog from './views/dialogs/ShowInfoScreenDialog'
import clubInfoQueryFile from './graphql/getClubInfo.query.gql'
import { isPlatform } from '@ionic/vue';
import store from './main'
import { setClubInfo, setAppPages } from '@/composition'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { Geolocation } from '@capacitor/geolocation';
import translate from '@/plugins/translate';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { App } from '@capacitor/app'

import { codePush, InstallMode  } from 'capacitor-codepush';
import devTools from "devtools-detect"

/*import {
  Plugins,
} from '@capacitor/core';

const { PushNotifications } = Plugins;*/

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonImg, IonAvatar,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonBadge, IonPopover,
  },
  data() {
    return {
      logocio: require('@/assets/images/logo/logocio.png'),
      apple: require('@/assets/images/apple.png'),
      google: require('@/assets/images/google.png')
    }
  },
  setup() {
    let enabled = ref(false)
    let enableNav = ref(false)
    /*if (devTools.isOpen && !isPlatform('ios') && !isPlatform('android') && store.getters.httpLink != 'http://localhost:4000' && !window.location.search.includes('2ec9dfb18c685ab3c38375f3d80f3481.access')) {
      return { enabled, enableNav }
    }*/
    function calcCrow(lat1, lon1, lat2, lon2) 
    {
      var R = 6371; // km
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }
    function toRad(Value) 
    {
        return Value * Math.PI / 180;
    }

    const router = useRouter()
    const route = useRoute();
    enableNav = ref(!(route.name == 'TournamentResultList') && !(route.name == 'TournamentLiveScoring') && !(route.name == 'TournamentLiveScoringHH') && !(route.name == 'TournamentStartList') && !(route.name == 'TournamentOverview') && !(route.name == 'Infoscreen') && !(route.name == 'PaceOfPlay') && !(route.name == 'Teetimestatus') && !(route.name == 'Startlistscreen') && !(route.name == 'NextCompetitionsscreen') && !(route.name == 'ScoreWeb') && !(route.name == 'GuestsQRCode'))
    const isLoggedIn = computed(() => store.getters.isLoggedIn ) 
    let deviceToken = ref('')
    const { mutate: sendTokenMutation } = useMutation(sendTokenMutationFile, { fetchPolicy: 'no-cache' });
    let batchNum = ref(0)
    let batchNumPerson = ref(0)
    let friendBatchNum = ref(0)

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    if (isPlatform('capacitor')) {
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token) => {
          console.log('token:', token)
          sendTokenMutation({token: token.value, os: (isPlatform('ios') ? 'ios' : 'android')})
          deviceToken.value = token.value
          //alert('Push registration success, token: ' + token.value);
          PushNotifications.removeAllDeliveredNotifications()
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error) => {
          alert('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
          toastController
            .create({
              message: notification.data.title + ': ' + notification.data.body,
              duration: 5000,
              color: 'warning',
            }).then(res => {
              res.present()
              res.onDidDismiss().then(() => {
                if (notification.data.isCard && notification.data.isCard == 'true' && route.path.startsWith('/card')) {
                  router.go()
                }
                else if (notification.data.isNews && notification.data.isNews == 'true') {
                  batchNum.value = batchNum.value + 1;
                  if (route.path.startsWith('/news'))
                    router.go()
                }
                else {
                  batchNumPerson.value = batchNumPerson.value + 1;
                  if (route.path.startsWith('/newsperson'))
                    router.go()
                }
              })
            })
          //alert(notification.data.title + ': ' + notification.data.body);
          //alert(JSON.stringify(notification));
          notification.data.badge = 0
          PushNotifications.removeDeliveredNotifications(PushNotifications.getDeliveredNotifications())
          PushNotifications.removeAllDeliveredNotifications()
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
          toastController
            .create({
              message: notification.notification.data.title + ': ' + notification.notification.data.body,
              duration: 5000,
              color: 'warning'
            }).then(res => {
              res.present()
              res.onDidDismiss().then(() => {
                if (isLoggedIn) {
                  if (notification.notification.data.isCard && notification.notification.data.isCard == 'true') {
                    if (route.path.startsWith('/card'))
                      router.go()
                    else {
                      store.dispatch('setSelectedIndex', appPages.value.findIndex(page => page.title.toLowerCase() === 'card'))
                      router.push('/card')
                    }
                  }
                  else if (notification.notification.data.isNews && notification.notification.data.isNews == 'true') {
                    if (route.path.startsWith('/news'))
                      router.go()
                    else {
                      store.dispatch('setSelectedIndex', appPages.value.findIndex(page => page.title.toLowerCase() === 'news'))
                      router.push('/news')
                    }
                  }
                  else {
                    if (route.path.startsWith('/newsperson'))
                      router.go()
                    else {
                      store.dispatch('setSelectedIndex', appPages.value.findIndex(page => page.title.toLowerCase() === 'newsperson'))
                      router.push('/newsperson')
                    }
                  }
                }
              })
            })
          //alert(JSON.stringify(notification));
          //alert(notification.notification.data.title + ': ' + notification.notification.data.body);
          PushNotifications.removeDeliveredNotifications(PushNotifications.getDeliveredNotifications())
          PushNotifications.removeAllDeliveredNotifications()
        }
      );

    }

    const isopenLang = ref(false)
    const event = ref()
    const selectedIndex = computed(() => store.getters.selectedIndex ) 
    let logo = ref('')
    let logoHeader = ref('')
    let googleTagID = ref('')
    let paypalClientID = ref('')
    let showInfoScreenPopup = ref(false)
    //const updateToast = ref(undefined)
    enabled = computed(() => enableNav.value && store.getters.httpLink != 'https://backend-cio-online-frontend-mobile.smrtp.net')
   
    const { mutate: logoutUserMutation } = useMutation(logoutUserMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: checkInMutation } = useMutation(checkInMutationFile, { fetchPolicy: 'no-cache' });

    const enableGoogleTag = (w,d,s,l,i) => {
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    }


    const {onResult, refetch: clubInfoRefetch, result: clubInfoResult } = useQuery(clubInfoQueryFile, () => ({ }), () => ({ fetchPolicy: 'no-cache', enabled: enabled.value }) )

    const clubInfo = useResult(clubInfoResult, null, data => data.getClubInfo)

    let appPages = ref([])
    onResult(queryResult => {
      if (queryResult && queryResult.data && queryResult.data.getClubInfo) {
          /*if (isLoggedIn.value && clubInfo.value.canCeckIn) {
            Geolocation.getCurrentPosition().then(res => {
              if (calcCrow(res.coords.latitude, res.coords.longitude, clubInfo.value.latitude, clubInfo.value.longitude) < 200) {
                alertController.create({
                  header: translate('checkIn'),
                  message: translate('checkInText'),
                  buttons: [
                    {
                      text: translate('no'),
                    },
                    {
                      text: translate('yes'),
                      handler: () => {
                        checkInMutation()
                      }
                    }
                    ] }).then(res => { res.present() })
              }

            }).catch(err => { })
          }*/

          store.dispatch('setSuccessfullBraintreePaypalAuthorized', queryResult.data.getClubInfo.isBraintreePaypalMethodExist)
          store.dispatch('setSuccessfullBraintreeCreditCardAuthorized', queryResult.data.getClubInfo.isBraintreeCreditCardMethodExist)
          store.dispatch('setMaskedNumber', queryResult.data.getClubInfo.maskedNumber)
          store.dispatch('setExpirationDate', queryResult.data.getClubInfo.expirationDate)
          logo.value = queryResult.data.getClubInfo.logo
          logoHeader.value = queryResult.data.getClubInfo.logoHeader
          googleTagID.value = queryResult.data.getClubInfo.googleTagID
          if (googleTagID.value && googleTagID.value != '')
            enableGoogleTag(window,document,'script','dataLayer',googleTagID.value)

          const openModal = async () => {
            const modal = await modalController.create({
              component: ShowInfoScreenDialog,
            });
            return modal.present();
          };
          showInfoScreenPopup.value = queryResult.data.getClubInfo.showInfoScreenPopup
          if (showInfoScreenPopup.value == true) {
            openModal()
          } 

          setClubInfo(queryResult.data.getClubInfo)
          appPages.value = []
          appPages.value.push(
            {
              title: 'home',
              url: '/home',
              icon: homeOutline,
              requiresLogin: false,
            })
          setAppPages(queryResult.data.getClubInfo.appPages.filter(f => f.showInNav != false))
      }

      for (const page of queryResult.data.getClubInfo.appPages) {
        if (!page.showInNav)
          continue
        page.icon == 'calendarOutline' ? page.icon = calendarOutline : page.icon == null
        page.icon == 'medalOutline' ? page.icon = medalOutline : page.icon == null
        page.icon == 'fastFoodOutline' ? page.icon = fastFoodOutline : page.icon == null
        page.icon == 'statsChartOutline' ? page.icon = statsChartOutline : page.icon == null
        page.icon == 'settingsOutline' ? page.icon = calendarOutline : page.icon == null
        page.icon == 'peopleOutline' ? page.icon = peopleOutline : page.icon == null
        page.icon == 'personOutline' ? page.icon = personOutline : page.icon == null
        page.icon == 'schoolOutline' ? page.icon = schoolOutline : page.icon == null
        page.icon == 'peopleCircleOutline' ? page.icon = peopleCircleOutline : page.icon == null
        page.icon == 'reorderFourOutline' ? page.icon = reorderFourOutline : page.icon == null
        page.icon == 'createOutline' ? page.icon = createOutline : page.icon == null
        page.icon == 'golfOutline' ? page.icon = golfOutline : page.icon == null
        page.icon == 'cardOutline' ? page.icon = cardOutline : page.icon == null
        page.icon == 'telescopeOutline' ? page.icon = telescopeOutline : page.icon == null
        page.icon == 'storefrontOutline' ? page.icon = storefrontOutline : page.icon == null
        page.icon == 'giftOutline' ? page.icon = giftOutline : page.icon == null
        
        if (page.title == 'competitions' || page.title == 'events' || page.title == 'courses')
          page.url += ('/' + dayjs.tz().format('YYYY-MM'))  
        appPages.value.push(page)
      }
      appPages.value.push(
        {
          title: 'profile',
          url: '/profile',
          icon: personOutline,
          requiresLogin: true,
        })
      appPages.value.push(
        {
          title: 'myclub',
          url: '/myclub',
          icon: idCardOutline,
          requiresLogin: true,
        })
      appPages.value.push(
        {
          title: 'newsperson',
          url: '/newsperson',
          icon: newspaperOutline,
          requiresLogin: true,
        })
      appPages.value.push(
        {
          title: 'news',
          url: '/news',
          icon: newspaperOutline,
          requiresLogin: true,
        })
      appPages.value.push(
        {
          title: 'tutorial',
          url: '/tutorial',
          icon: helpCircleOutline,
          requiresLogin: false,
        })
      appPages.value.push(
        {
          title: 'verify',
          url: '/verify',
          icon: settingsOutline,
          requiresLogin: false,
          showInNav: false
        })
      appPages.value.push(
        {
          title: 'reset',
          url: '/reset',
          icon: settingsOutline,
          requiresLogin: false,
          showInNav: false
        })
      const path = window.location.pathname.split('/')[1];
      if (path !== undefined) {
        store.dispatch('setSelectedIndex', appPages.value.findIndex(page => page.title.toLowerCase() === path.toLowerCase()))
      }
      

    })

    /*if (isLoggedIn.value && isPlatform('capacitor')) {
        PushNotifications.checkPermissions().then( result => {
          console.log("🚀 ~ PushNotifications.checkPermissions1 ~ result:", result)
          if (result.receive === 'prompt') {
            PushNotifications.requestPermissions().then( result1 => {
              console.log("🚀 ~ PushNotifications.requestPermissions1 ~ result1:", result1)
              if (result1.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
              } else {
                PushNotifications.checkPermissions().then( result2 => {
                  console.log("🚀 ~ PushNotifications.checkPermissions1 ~ result2:", result2)
                  if (result2.receive === 'granted') {
                    PushNotifications.register();
                  }
                })
                // Show some error
              }
            });
          }
          else if (result.receive === 'granted') {
            PushNotifications.register();
          }
          else {
            // remove token
            sendTokenMutation({token: '', os: (isPlatform('ios') ? 'ios' : 'android')})
          }
        })
    }*/

    watch(isLoggedIn, async (newVal, oldVal) => {
      if (newVal && isPlatform('capacitor')) {
        let permStatus = await PushNotifications.checkPermissions()
        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive === 'granted') {
          await PushNotifications.register()
        }

        /*  PushNotifications.checkPermissions().then( result => {
            console.log("🚀 ~ PushNotifications.checkPermissions ~ result:", result)
            let permStatus = await PushNotifications.requestPermissions()
            if (result.receive === 'prompt') {
              PushNotifications.requestPermissions().then( result1 => {
                console.log("🚀 ~ PushNotifications.requestPermissions ~ result1:", result1)
                if (result1.receive === 'granted') {
                  // Register with Apple / Google to receive push via APNS/FCM
                  PushNotifications.register();
                } else {
                  PushNotifications.checkPermissions().then( result2 => {
                    console.log("🚀 ~ PushNotifications.checkPermissions ~ result2:", result2)
                    if (result2.receive === 'granted') {
                      PushNotifications.register();
                    }
                  })
                }
              });
            }
            else if (result.receive === 'granted') {
              PushNotifications.register();
            }
            else {
              // remove token
              sendTokenMutation({token: '', os: (isPlatform('ios') ? 'ios' : 'android')})
            }
          })*/
      }
      //if (newVal && window.location.href.includes('localhost')) {
        //router.go()
      //}
    },{immediate:true})

    return { 
      selectedIndex,
      appPages, 
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, giftOutline,
      warningSharp, batchNum, enableNav, friendBatchNum, batchNumPerson,
      calendarSharp, schoolOutline, peopleCircleOutline, reorderFourOutline,
      calendarOutline, logInOutline, logOutOutline, arrowForwardCircleOutline, removeOutline, eyeOutline, caretUp, addOutline,businessOutline, newspaperOutline, informationOutline, telescopeOutline, storefrontOutline, isPlatform,
      peopleOutline, personAddOutline,settingsOutline, personOutline, personSharp, homeOutline, addCircleOutline,
      isSelected: (url) => url === route.path ? 'selected' : '', isopenLang, event, deviceToken, informationCircleOutline,
      helpOutline,helpCircleOutline,helpBuoyOutline, logo, logoHeader, googleTagID, clubInfoRefetch, cardOutline, enabled, isLoggedIn, chevronDownOutline, showInfoScreenPopup,
      logoutUserMutation, createOutline, fastFoodOutline, globeOutline, clubInfo, route, golfOutline, chevronBackOutline, router
      
    }
  },
  computed: {
      UserData : function(){ return this.$store.getters.UserData},
      selectedClub : function(){ return this.$store.getters.selectedClub},
      clubs : function(){ return JSON.parse(this.$store.getters.clubs)},
      lang : function(){ return this.$store.getters.language},
      isMainPath: function(){ return this.$route.path.split('/').length == 2 || this.$route.path.includes('competitions') || this.$route.path.includes('courses') },
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
  },
  methods: {
    openUrl(url) {
        window.open(url, '_system')
    },
    onRightClick() {  
    }, 
    async impressum() {
      const modal = await modalController
        .create({
          component: ImprintDialog,
        })
      return modal.present();
    },
    async setLanguage(language) {
      if (this.lang != language) {
        await Storage.setItem('language', language);
        //this.$store.dispatch('setLanguage')
        //this.$changeLanguage(language)
        setTimeout(() => { this.$router.go() }, 0)
      }
      this.openLang(false)
    },
    openLang(state, event) {
      this.isopenLang = state
      this.event=event
    },
    async goClubSelection() {
      await Storage.setItem('httpLink', 'https://backend-cio-online-frontend-mobile.smrtp.net')
      this.$store.dispatch('setHttpLink')
      if (this.isLoggedIn)
        await this.logout(false)
      this.$store.dispatch('logout')
      this.$router.push('/home')
      this.$router.go()
    },
    async addCredit() {
      const modal = await modalController
        .create({
          component: AddCreditDialog,
        })
        modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              store.dispatch('setCreditAmount', this.creditAmount + parseFloat(data.data))
            }
          })
      return modal.present();
    },
    async goBack() {
      let path = this.$route.path
      if (this.$route.path.includes('livescoringrpr'))
        path = this.$route.path.replace('livescoringrpr', 'livescoring')
      if (this.$route.path.includes('livescoringsc'))
        path = this.$route.path.replace('livescoringsc', 'livescoring')
      if (this.$route.path.includes('livescoringonly'))
        path = this.$route.path.replace('livescoringonly', 'livescoring')

      this.$router.push(path.substring(0, path.split('/', 2).join('/').length) + "s")
    },
    async openHelpCourses() {
      const modal = await modalController
        .create({
          component: HelpCoursesDialog,
        })
      return modal.present();
    },
    async openHelpRange() {
      const modal = await modalController
        .create({
          component: HelpBookingRangeDialog,
        })
      return modal.present();
    },
    async openHelpWellness() {
      const modal = await modalController
        .create({
          component: HelpBookingWellnessDialog,
        })
      return modal.present();
    },
    async openHelpTrackman() {
      const modal = await modalController
        .create({
          component: HelpBookingTrackmanDialog,
        })
      return modal.present();
    },
    async openHelpIndoor() {
      const modal = await modalController
        .create({
          component: HelpBookingIndoorDialog,
        })
      return modal.present();
    },
    async openHelpFriends() {
      const modal = await modalController
        .create({
          component: HelpFriendsDialog,
        })
      return modal.present();
    },
    async openHelpPro() {
      const modal = await modalController
        .create({
          component: HelpBookingProDialog,
        })
      return modal.present();
    },
    async openHelpTeetime() {
      const modal = await modalController
        .create({
          component: HelpBookingTeetimeDialog,
        })
      return modal.present();
    },
    async openHelpTournament() {
      const modal = await modalController
        .create({
          component: HelpTournamentDialog,
        })
      return modal.present();
    },
    async openHelpEvent() {
      const modal = await modalController
        .create({
          component: HelpEventDialog,
        })
      return modal.present();
    },
    async openHelpCompetitions() {
      const modal = await modalController
        .create({
          component: HelpCompetitionDialog,
        })
      return modal.present();
    },
    isPlatform(platform) {
      return isPlatform(platform)
    },
    canEditCompany() {
        return this.isLoggedIn
    },
    async openRegister() {
      menuController.close()
      const modal = await modalController
        .create({
          cssClass: this.isPlatform('mobile') ? '' : 'editBooking-modal',
          component: RegisterDialog,
        })
      return modal.present();
    },
    async checkLogout(showToast = true) {
      const alert = await alertController
        .create({
          header: 'Logout',
          message: this.$t('wantToLogout'),
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async (showToast) => {
                this.logout()
              },
            },
          ],
        });
      return alert.present();
    },
    async logout(showToast = true) {
        const refreshToken = await Storage.getItem('refreshToken')
        this.logoutUserMutation({
          refreshToken: refreshToken,
          deviceToken: this.deviceToken
        })
        .then(res => {
          this.$store.dispatch('logout')

          if (showToast)
            toastController
              .create({
                message: this.$t('logoutsuccess'),
                duration: 2000,
                color: 'success'
              }).then(res => res.present())
          //if (!this.$route.path.startsWith('/teetime')) {
            this.$store.dispatch('setSelectedIndex', this.appPages.findIndex(page => page.title.toLowerCase() === 'home'))
            this.$router.push('/home')
          //}
          //this.$router.go()
        })
        .catch(err => {
          if (showToast)
            toastController
              .create({
                message: this.$t('logoutnosuccess'),
                duration: 2000,
                color: 'danger',
              }).then(res => res.present())
        })
    },
    async login() {
      menuController.close()
      const modal = await modalController
        .create({
          component: LoginDialog,
        })
        modal.onDidDismiss()
          .then(async (data) => {
            if (data.data) {
              if (this.$route.path.startsWith('/verify') || this.$route.path.startsWith('/reset'))
                await this.$router.push('/home')
              this.clubInfoRefetch()
              if (!this.$route.path.startsWith('/home'))
                this.$router.go()
            }
          })
      return modal.present();
    },
    increment() {
      this.$store.commit('increment')
    },
    async aboutApp(){
      const modal = await modalController
        .create({
          component: AboutApp,
        })
      return modal.present();
  },
  checkMobileAppUpdate(){      
      try {        
        var onError = function (error) {
            console.log("An error occurred. " + error);            
            toastController.create({message: error,duration: 5000,color: 'danger'}).then(res => res.present())
        };
        codePush.checkForUpdate(this.onUpdateCheck, onError);
      } catch (error) {
        toastController.create({message: error,duration: 5000,color: 'danger'}).then(res => res.present())
      }
    },       
    onUpdateCheck(remotePackage) {          
      if (!remotePackage) {
          console.log("The application is up to date.");
      } else {    
          // The hash of each previously reverted package is stored for later use.
          // This way, we avoid going into an infinite bad update/revert loop.
          if (!remotePackage.failedInstall) {
              console.log("A CodePush update is available. Package hash: " + remotePackage.packageHash);
              //this.updateToast = toastController.create({message: this.$t('appUpdating'),color: 'success'}).then(res => res.present())          
              toastController.create({message: this.$t('appUpdating'),duration: 5000,color: 'success'}).then(res => res.present())          
              this.codePushSync(true)
          } else {
              console.log(remotePackage.label +" The available update was attempted before and failed.")
              this.requestUpdate()
          }
      }
    },
    async requestUpdate() {
      const modal = await modalController.create({component: YesDialog,
          componentProps: {
            propsData: {
              title: '',
              message: this.$t('updateAppToUSe')
            }
          },})
      
      modal.onDidDismiss().then(data => {
        if(data.data){
          //this.updateToast = toastController.create({message: this.$t('appUpdating'),color: 'success'}).then(res => res.present())          
          this.codePushSync(false)
        }
      });
      return await modal.present();
    },
    codePushSync(ignoreFailedUpdates){

      try {      
        console.log('deviceready checkNewVersion', codePush, InstallMode)
        codePush.sync(
                  {
                    ignoreFailedUpdates: ignoreFailedUpdates,
                    installMode:InstallMode.IMMEDIATE,
                    mandatoryInstallMode: InstallMode.IMMEDIATE,
                    onSyncStatusChanged: (status) => {
                      console.log('codePush onSyncStatusChanged status',status)
                      
                      switch (status) {
                          case 0:
                              console.log('onSyncStatusChanged status UP_TO_DATE')
                              break;
                          case 1:
                              console.log('onSyncStatusChanged status UPDATE_INSTALLED')
                              codePush.notifyApplicationReady()
                              break;
                          case 2:
                              console.log('onSyncStatusChanged status UPDATE_IGNORED')
                              break;
                          case 3:
                              console.log('onSyncStatusChanged status ERROR')
                              break;
                          case 4:
                              console.log('onSyncStatusChanged status IN_PROGRESS')
                              break;
                          case 5:
                              console.log('onSyncStatusChanged status CHECKING_FOR_UPDATE')
                              break;
                          case 6:
                              console.log('onSyncStatusChanged status AWAITING_USER_ACTION')
                              break;
                          case 7:
                              console.log('onSyncStatusChanged status DOWNLOADING_PACKAGE')
                              break;
                          case 8:
                              console.log('onSyncStatusChanged status INSTALLING_UPDATE')
                              break;
                      }
                    },
                  },
                  (progress) => {
                    console.log(`codePush Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`);
                  }
                )
                .then((status) => {
                  //if (this.updateToast)
                    //this.updateToast.dismiss()
                  console.log('codePush status ',status)
                  //if (status == 1)
                    //codePush.notifyApplicationReady();
                });
      } catch (error) {
        //if (this.updateToast)
          //this.updateToast.dismiss()
        console.log('codePush error',error)
      }

    },
  },
  async mounted() {
   this.$store.dispatch('getSelectedClub')

   //if (isPlatform('capacitor')) {
     // const updateTime = await Storage.getItem('updateChecked');
      //if (!updateTime || dayjs(updateTime).add(5, 'minute') < dayjs()) {
        //await Storage.setItem('updateChecked', dayjs().format('YYYY-MM-DD HH:mm:ss'));
        //this.checkMobileAppUpdate()
      //}
  /*    App.addListener('appStateChange', (state) => {
          console.log("🚀 ~ file: App.vue:1040 ~ App.addListener ~ state:", state)
          if (state.isActive) {
            this.checkMobileAppUpdate()
          }
      });*/
    //} 
  },
});

</script>


<style scoped>

.clickable {
    cursor: pointer;
}

ion-menu-toggle.submenu {
    overflow: hidden;
    display: block;
    height: 10;
    transition: height .3s linear;
    padding-left: 20%;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#start-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#start-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#favorites-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-list#search-result-list {
  padding-top: 0px;

  font-size: 16px;

  color: #757575;

  min-height: 26px;

  margin-bottom: 8px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#favorites-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}


</style>

<style lang="scss">

.ion-color-successlight {
  --ion-color-base: var(--ion-color-successlight);
  --ion-color-base-rgb: var(--ion-color-successlight-rgb);
  --ion-color-contrast: var(--ion-color-successlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-successlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-successlight-shade);
  --ion-color-tint: var(--ion-color-successlight-tint);
}

.ion-color-warninglight {
  --ion-color-base: var(--ion-color-warninglight);
  --ion-color-base-rgb: var(--ion-color-warninglight-rgb);
  --ion-color-contrast: var(--ion-color-warninglight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warninglight-contrast-rgb);
  --ion-color-shade: var(--ion-color-warninglight-shade);
  --ion-color-tint: var(--ion-color-warninglight-tint);
}

.ion-color-dangerlight {
  --ion-color-base: var(--ion-color-dangerlight);
  --ion-color-base-rgb: var(--ion-color-dangerlight-rgb);
  --ion-color-contrast: var(--ion-color-dangerlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dangerlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-dangerlight-shade);
  --ion-color-tint: var(--ion-color-dangerlight-tint);
}

.ion-color-female {
  --ion-color-base: var(--ion-color-female);
  --ion-color-base-rgb: var(--ion-color-female-rgb);
  --ion-color-contrast: var(--ion-color-female-contrast);
  --ion-color-contrast-rgb: var(--ion-color-female-contrast-rgb);
  --ion-color-shade: var(--ion-color-female-shade);
  --ion-color-tint: var(--ion-color-female-tint);
}

.ion-color-member {
  --ion-color-base: var(--ion-color-member);
  --ion-color-base-rgb: var(--ion-color-member-rgb);
  --ion-color-contrast: var(--ion-color-member-contrast);
  --ion-color-contrast-rgb: var(--ion-color-member-contrast-rgb);
  --ion-color-shade: var(--ion-color-member-shade);
  --ion-color-tint: var(--ion-color-member-tint);
}

.ion-color-guest {
  --ion-color-base: var(--ion-color-guest);
  --ion-color-base-rgb: var(--ion-color-guest-rgb);
  --ion-color-contrast: var(--ion-color-guest-contrast);
  --ion-color-contrast-rgb: var(--ion-color-guest-contrast-rgb);
  --ion-color-shade: var(--ion-color-guest-shade);
  --ion-color-tint: var(--ion-color-guest-tint);
}

.ion-color-male {
  --ion-color-base: var(--ion-color-male);
  --ion-color-base-rgb: var(--ion-color-male-rgb);
  --ion-color-contrast: var(--ion-color-male-contrast);
  --ion-color-contrast-rgb: var(--ion-color-male-contrast-rgb);
  --ion-color-shade: var(--ion-color-male-shade);
  --ion-color-tint: var(--ion-color-male-tint);
}

ion-app.platform-ios12 ion-content { pointer-events: auto; }
ion-app.platform-ios14 ion-content { pointer-events: auto;  }

.ios ion-content {
   pointer-events: auto;
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 45px;
  bottom: 3px;
  background-color: lightgray;
  padding: 8px;
  border-radius: 15px;
  color: black;
  box-shadow: 0 3px 5px -1px grey;
}

.register-modal .modal-wrapper {
    height: 75%;
 }

.booking-modal .modal-wrapper {
    height: 40%;
 }

.edit-modal .modal-wrapper {
    height: 100%;
 }

.split-pane-visible >.split-pane-side {
  min-width: 200px; /* Default: 270px */
  max-width: 304px; /* Same as when menu opens in mobile mode */
}

.tournamentLists-modal .modal-wrapper {
    width: 80%;
 }

.editBooking-modal .modal-wrapper {
    width: 70%;
    height: 95%;
 }


</style>

<style>

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Minion";
  src: local("Minion"),
   url(./fonts/MinionPro-Regular.otf) format("otf");
}
</style>