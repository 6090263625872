module.exports = {
  translation: {
    "clubinone": "CLUBINONE 4U",
    "home": "My club",
    "pros": "Pros",
    "persons": "Persons",
    "competitions": "Tournaments",
    "competition": "tournament",
    "competitionUC": "Tournament",
    "bookings": "Bookings",
    "statistics": "Statistics",
    "settings": "Settings",
    "profile": "Profile",
    "login": "Login",
    "logout": "Logout",
    "organizations-list": "My Organizations",
    "companies": "Clubs",
    "company": "Club",
    "searchcompanies": "Search clubs",
    "searchresult": "Search result",
    "history": "Recent",
    "register": "Register",
    "save": "Save",
    "userData": "User data",
    "changePassword": "Change password",
    "changeEmail": "Change Email",
    "language": "Language",
    "firstName": "First name",
    "lastName": "Last name",
    "birthdate": "Birthday",
    "password": "Password",
    "email": "Email",
    "profilePicture": "Profile picture",
    "takePicture": "Select picture",
    "upload": "Save image",
    "passwordReset": "Send passwort reset email",
    "events": "Events",
    "event": "event",
    "eventUC": "Event",
    "loading": "Loading data ...",
    "newcompanies": "Become a host",
    "erroLogin": "Please login to create a club!",
    "newCategory": "New Category",
    "description": "Description",
    "changeSuccessful": "Change successful",
    "changeNotSuccessful": "Error during change",
    "deleteSuccessful": "Delete successful",
    "deleteNotSuccessful": "Error during delete",
    "addSuccessful": "Add successful",
    "addNotSuccessful": "Error during add",
    "notSuccessful": "An error occured",
    "name": "Name",
    "shortName": "Short name",
    "priority": "Priority",
    "delete": "Delete",
    "cancel": "Cancel",
    "ok": "OK",
    "bookingDuration": "Booking duration",
    "showAvailableSlots": "Show available slots",
    "showBookedSlots": "Show booked slots",
    "withSlider": "Show slider",
    "intervallBetweenBookings": "Time between two bookings",
    "maxBookingsInFuture": "Max. bookings",
    "maxBookingsPerDay": "Max. bookings per day",
    "maxBookingsPerWeek": "Max. bookings per week",
    "maxPersonsBookable": "Max. number bookable slots",
    "minPersonsBookable": "Min. number bookable slots",
    "newBookingType": "New booking type",
    "from": "Period from",
    "to": "Period to",
    "cart": "Cart",
    "teetimes": "Teetimes",
    "pro": "Pro",
    "pros": "Pro",
    "courses": "Courses",
    "course": "Course",
    "ranges": "Range",
    "available": "",
    "sperrungnoshow": "Locked",
    "showAvatar": "Show avatar in booking calendars",
    "withDog": "With dog",
    "with9Hole": "Plans to play 9 holes",
    "withPlayWithMe": "Happy to book with me",
    "member": "Member",
    "memberFemale": "Member female",
    "memberMale": "Member male",
    "guest": "Guest",
    "guestFemale": "Guest female",
    "guestMale": "Guest male",
    "displayOptions": "Display Options",
    "showName": "Show name",
    "showHCP": "Show handicap ",
    "showGender": "Show gender",
    "all": "All",
    "min1": "Min. 1 free spot",
    "min2": "Min. 2 free spots",
    "min3": "Min. 3 free spots",
    "min4": "Min. 4 free spots",
    "hcp": "Handicap",
    "syncHCP": "Synchronize handicap",
    "hcpSyncOK": "Handicap synchronization successful",
    "hcpErrorSync": "Error synchronizing handicap",
    "bookingTeetimeHelp": "Help teetime booking",
    "myBooking": "My booking",
    "monday": "Mon",
    "tuesday": "Tue",
    "wednesday": "Wed",
    "thursday": "Thur",
    "friday": "Fri",
    "saturday": "Sat",
    "sunday": "Sun",
    "makeBooking": "Book the teetime",
    "makeBookingAndPay": "Book and pay teetime",
    "makeProBooking": "Book pro lesson",
    "makeAndPayProBooking": "Book and pay pro lesson",
    "makeRangeBooking": "Book range slot",
    "makeAndPayRangeBooking": "Book and pay range slot",
    "maxDays": "Max. days for booking",
    "timeSwitch": "Time add additional day",
    "allowAlwaysBookingHours": "Hours always bookable",
    "courseConfigs": "Course config",
    "unlimited": "No restrictions",
    "myCourseData": "My data",
    "myFutureBookings": "Bookings total",
    "myBookingToday": "Bookings selected date",
    "myBookingWeek": "Bookings this week",
    "bookingCourseHelp": "Help Course Configuration",
    "bookingCourseHelp1": "These configurations have been deposited by the golf club.",
    "openFriends": "Configure friends",
    "editBooking": "Edit booking",
    "@me": "Me",
    "player": "Player",
    "username": "Id-Card number or nickname",
    "announcement" : "Announcement",
    "participants" : "Participants",
    "of" : "of",
    "round" : "Round",
    "guests": "Guests",
    "webAvailable": "Available in web",
    "PF0": "Single",
    "PF1": "Aggregat Foursome",
    "PF2": "Foursome",
    "PF3": "Foursome best drive",
    "PF4": "Chapman Foursome",
    "PF5": "Ryesome Foursome",
    "PF6": "Fourball",
    "PF7": "Fourball Bestball",
    "PF8": "Scramble",
    "PF9": "Twosome Scramble",
    "PF10": "Texas Scramble",
    "PF11": "Florida Scramble",
    "PF12": "RPR",
    "SM0": "Strokeplay",
    "SM1": "Stableford",
    "SM2": "Against Par",
    "SM3": "Matchplay",
    "SM4": "Strokeplay and Stableford",
    "SM5": "Mixed",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Strokeplay max score",
    "W0": "18 holes",
    "W1": "9 holes",
    "HCPREL0": "Not HCPI™-Relevant",
    "HCPREL1": "HCPI™-Relevant",
    "HCPREL2": "Tiger Rabbit",
    "regPeriod": "Registration period",
    "regPeriodStart": "Opening of registrations",
    "regPeriodEnd": "Closing date for registrations",
    "hcpi": "HCPI™",
    "toStr": "to",
    "timerRunning": "The teetime is still waiting...",
    "timerStopped": "Your teetime is now also available to other customers.",
    "teetimeLocked": "Teetime is blocked",
    "tournamentRegisterError1": "Not logged in",
    "tournamentRegisterError2": "Gender not matching",
    "tournamentRegisterError3": "Handicap not matching",
    "tournamentRegisterError4": "Registration period not matching",
    "tournamentRegisterError5": "Entry list is full",
    "tournamentRegisterError6": "Agegroup not matching",
    "tournamentRegisterError7": "Registered in #",
    "tournamentUnRegisterError1": "Not logged in",
    "tournamentUnRegisterError2": "Unregister not allowed",
    "tournamentUnRegisterError3": "Unregister date exceeded",
    "tournamentUnRegisterError4": "Not registered in #",
    "tournamentUnRegisterError5": "Unregister not allowed because payed online",
    "helpCoursesTime": "Course date, start and end time",
    "helpCoursesEnter": "Register for course",
    "helpCoursesUnregister": "Unregister from course",
    "helpCoursesSeries" : "Course series",
    "helpCourses": "Help Courses",
    "helpTournamentTime": "Tournament date, start and end time",
    "helpTournamentDown": "Open details",
    "helpTournamentUp": "Close details",
    "helpTournamentEnter": "Register",
    "helpTournamentEnterWaitlist": "Register for waitlist",
    "helpTournamentUnregister": "Unregister",
    "helpTournamentGenderFemale": "Tournament allowed for females",
    "helpTournamentGenderMale": "Tournament allowed for males",
    "helpTournamentML": "Entry list",
    "helpTournamentSL": "Start list",
    "helpTournamentEL": "Result list",
    "helpTournamentAnnouncment": "Announcement",
    "helpTournament": "Help Tournaments",
    "helpEventTime": "Event date, start and end time",
    "helpEventDown": "Open details",
    "helpEventUp": "Close details",
    "helpEventEnter": "Register for event",
    "helpEventEnterWaitlist": "Register for event waitlist",
    "helpEventUnregister": "Unregister from event",
    "helpEventGenderFemale": "Event allowed for females",
    "helpEventGenderMale": "Event allowed for males",
    "helpEventAnnouncment": "Description",
    "helpEvent": "Help Events",
    "helpRoundSL": "Start list",
    "unregister": "Sign out from",
    "unregisterSuccessful": "Signed out successfully",
    "unregisterNotSuccessful": "Error during sign out",
    "registerSuccessful": "Registered successfully",
    "registerNotSuccessful": "Error during register",
    "none": "No selection",
    "early": "Early",
    "late": "Late",
    "makeTournamentRegistration": "Register",
    "makeTournamentRegistrationAndPay": "Register and Pay",
    "makeTournamentUnRegistration": "Unregister",
    "comment": "Comment",
    "registerTournament": "Register",
    "unregisterTournament": "Unregister",
    "addAdditionalPlayers": "Additional Teamplayers",
    "addAdditionalFlightPlayers": "Additional players",
    "searchPlayer": "Search players",
    "friends": "Friends",
    "friendGroups": "Friend groups",
    "friendsSave": "Save Friends",
    "addAdditionalFriends": "Add friends",
    "friendsNotConfirmed": "Friend request",
    "friendsDeclined": "Declined friend requests",
    "noTournaments": "No tournaments found",
    "noEvents": "No events found",
    "noTeetimeCourseFound": "No teetime booking calendar found",
    "hole": "Hole",
    "given": "given",
    "noPros": "No Pros found",
    "noRanges": "No range slots found",
    "nextFreeSlot": "Todays next free slot",
    "booked": "booked",
    "precedingHours": "Preceding hours to book a slot",
    "precedingStornoMinutes": "Minutes until a booking can be deleted",
    "proHelp": "Help Pro",
    "proConfigs": "Pro Configs",
    "rangeHelp": "Help Range",
    "rangeConfigs": "Range Configuration",
    "noCourses": "No courses found",
    "coursename": "Golf course",
    "placename": "Place",
    "priceinfo": "Price info",
    "onlinecomments": "Comment",
    "livescorings": "Digital scoring",
    "noLivescorings": "No tournaments found today",
    "noEmail": "There is no email address available. Please contact the club to have your email address added.",    
    "noEmailSMSLogIn": "There is no e-mail address. You can set your own e-mail via your mobile phone number or contact the club to have your e-mail address stored",
    "notActivated": "Your account is not activated. Please click on send activation link to verify your email. Is the e-mail address no longer current? Then please inform the golf club of the current e-mail address and repeat the process.",
    "sendActivationLink": "Send activation link",
    "emailSendOK": "Email sent successfully",
    "emailSendError": "Error sending the email",
    "verify": "Activate account",
    "verifyEmailOK": "The account was successfully activated.",
    "verifyEmailError": "Error activating the account",
    "reset": "Set new password",
    "resetPassword": "Change password",
    "passwordConfirm": "Confirm password",
    "password8Chars": "At least 8 characters long",
    "password1UpperCase": "At least 1 capital letter",
    "password1Number": "At least one number",
    "password1SpecialChar": "At least one special character (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Passwords match",
    "resetPasswordOK": "Password successfully changed",
    "resetPasswordError": "Error changing password",
    "passwordRules": "Mind. 8 chars long, one upper case, one number and one special char (-, +, _, !, @, #, $, %, ^, &, *, .)",
    "bookingDeleted": "Booking successfully deleted",
    "bookingAdded": "Booking successfully added",
    "dgvnumber": "10-digit ID number (exclusively for members of golf associations)",
    "dgvdescription": "Register quickly and easily now!",
    "dgvdescription1": "Member of one of the three golf associations listed?",
    "dgvdescription2": "Then please select the respective card and enter the 10-digit ID number, as well as the personal data.",
    "searchClubs": "Search for country/club if not member in a german, swiss golf or austrian club",
    "searchCountry": "Search country/club",
    "gender": "Gender",
    "male": "Male",
    "female": "Female",
    "title": "Title",
    "activationLinkSent": "An activation link has been sent via email. Please check your inbox and activate your account. The email may have also ended up in the spam folder. Your user login for the login is: #",
    "cardclub": "Card",
    "marker": "Marker",
    "clear": "Clear",
    "confirm": "Confirm",
    "signaturePlayer": "Signature Player",
    "signatureMarker": "Signature Marker",
    "read": "Read",
    "gentleman": "Men",
    "ladies": "Women",
    "missingScores": "Scores are missing. Confirmation of Signature not possible!",
    "hometeetimes":"Book teetime",
    "homefriends":"My friends",
    "homecard":"My RFID cards",
    "homepros":"Book Pro",
    "homecourses":"Book course",
    "homeranges":"Book range slot",
    "homecompetitions":"Tournament calendar",
    "homelivescorings":"Scorecard in app",
    "homeevents":"Events calendar",
    "homeMemberdata":"My data",
    "homePayment":"In App Payment",
    "homeMember":"Already a member or guest? Then use all online services directly after login.",
    "homeEmail":"Email address stored in the club?",
    "homeNewCustomer":"Register now as a new guest and use all online services.",
    "myclub":"My data",
    "personInfo": "Personal Information",
    "clubInfo": "Club Information",
    "addressInfo": "Address Information",
    "bankInfo": "Bank Information",
    "authorizePayments": "Authorize payments",
    "revokePayments": "Revoke authorization",
    "paypalPayment": "Paypal payment",
    "paypalAuthorized":"You have successfully authorized PayPal payment",
    "creditCardPayment": "Credit card payment",
    "cardholderName": "Cardholder Name",
    "creditCardNumber": "Credit card number",
    "expiration": "Expiration",
    "accept": "Add credit card",
    "creditCardAuthorized":"You have successfully authorized Credit card payment",
    "creditCardAuthorizedFailed":"Authorizierung failed",
    "noPaymentMethod": "There is no payment method stored yet. Simply save in the profile PayPal or a credit card.",
    "paymentMethodDeleted": "The payment method was deleted",
    "paymentMethodCreated": "The payment method was added",
    "startdate": "Start date",
    "contractstart": "Contract start date",
    "contractend": "Contract end date",
    "enddate": "End date",
    "membership": "Membership",
    "contract": "Contract",
    "homeclubnum": "Home club number",
    "street": "Street",
    "city": "City",
    "telephone": "Telephone",
    "mobile": "Mobile",
    "newsletter": "Newsletter",
    "allowNewsletter": "Subscribed",
    "notAllowNewsletter": "Not subscribed",
    "emailbill": "Send bill by email",
    "allowBillEmail": "Subscribed",
    "notAllowBillEmail": "Not subscribed",
    "accountowner": "Account owner",
    "bank": "Bank",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Debit withdraw",
    "debitsOk": "Allowed",
    "debitsNotOk": "Not allowed",
    "refNumber": "Mandate reference number",
    "homeclub": "Home club",
    "credit": "Credit",
    "addFriendGroup": "Add friend group",
    "addCredit": "Recharge credit",
    "amount": "Amount",
    "recharge": "Recharge",
    "automaticRechargeBeyond": "Automatic recharge if credit is less than this amount",
    "paymenttype": "Payment option",
    "rechargeBy": "Increase by amount",
    "amountToPay": "Amount to pay",
    "freeSlots": "Free slots",
    "addAdditionalFlightFriends": "Additional friends",
    "card": "My card",
    "assignedCards": "Assigned cards",
    "valid": "Validity",
    "noCardFound": "No card found",
    "permissions": "Permissions",
    "status": "Status",
    "locked": "Locked",
    "active": "Active",
    "cardHistory": "Card history",
    "noHistory" : "No data available",
    "clubSelection": "Club selection",
    "bonuspoints": "Bonus points",
    "creditoncard": "Credit on card",
    "pointsoncard": "Points on the radio card",
    "getBalls": "Request getting balls",
    "getPutting": "Request open #",
    "getCheckIn": "Request check in",
    "getDoor": "Request door",
    "pressButton": "Press the silver button on the ballmachine",
    "ballmachineTimerRunning": "Press the silver button on the ballmachine within the next ",
    "checkInTimerRunning": "Press the silver button on the CheckIn within the next ",
    "doorTimerRunning": "Press the silver button on the door within the next ",
    "bookingTimerRunning": " Press the silver button on the reader within the next ",
    "seconds": "Seconds",
    "appButtonError": "Error handling the request. Please try again in a view seconds.",
    "r0000001": "Something went wrong while creating transaction",
    "r0000002": "You have created a payment. Please push the silver button on the reader to confirm the payment.",
    "r0000003": "The reader is being used by other people. Please wait a moment and try again",
    "r0000004": "This DGV number has already been used",
    "r0000005": "Hardware not found",
    "frequency0": "",
    "frequency1": "monthly",
    "frequency2": "quaterly",
    "frequency3": "half yearly",
    "frequency4": "yearly",
    "frequency5": "daily",
    "packageAvailable": "available",
    "generalPackage": "Ball basket packages",
    "personalPackage": "Personal ball basket packages",
    "noRPRs": "No registered private rounds found today",
    "RPRs": "Registered private rounds",
    "RPR": "Registered private round",
    "getBooking": "Request switch on",
    "news": "Golf Club News",
    "imprint": "Imprint",
    "alreadyInFlight": " is already in flight",
    "acceptAgb": "I accept the terms and conditions",
    "acceptDSGVO": "I accept the privacy policy",
    "agb": "General terms and conditions",
    "datalegal": "Privacy policy",
    "marshalls": "Marshall",
    "send": "Sent",
    "messageToOffice": "Message to office",
    "messageSent": "Message was sent",
    "statusSet": "Status set",
    "holes": "Holes",
    "tee": "Tee",
    "personalScorecard": "Personal scorecard",
    "getScorecard": "To Scorecard",
    "length": "Meters",
    "faq": "FAQs",
    "yes": "Yes",
    "no": "No",
    "checkIn": "Check In",
    "checkInText": "Should today's teetimes be set to present?",
    "DSGVO": "Privacy Policy",
    "Agb": "General Terms and Conditions",
    "noNews": "No news available",
    "noPlayers": "There are no players in the group",
    "functions": "The digital golf experiences listed below are provided by this golf club!",
    "wantToLogout": "I would like to log out.",
    "canLogin": "Now log in with the assigned password and this 10-digit number: ",
    "registerGuest": "Registration for guests",
    "nickname": "Nickname",
    "contactClub": "To change data, please contact the club.",
    "today": "Today",
    "hcpiInputHint": "Enter HCPI™ as a number. Use a dot as a separator for the decimal point.",
    "noInternet": "Not all results are transferred. Please check the internet connection!",
    "closestClub": "The closest club",
    "mobileScreen1": "Digital golf experiences at your golf club",
    "mobileScreen2": "Range booking",
    "mobileScreen3": "Pros & Courses",
    "mobileScreen4": "Manage Cards",
    "deleteAccount": "Delete account",
    "wantToDeleteAccount": "Delete the account?",
    "noWellnesses": "No wellness slots found",
    "wellnesses": "Wellness",
    "wellnessHelp": "Help Wellness",
    "wellnessConfigs": "Wellness configuration",
    "makeWellnessBooking": "Book wellness slot",
    "homewellnesses":"Book wellness slot",
    "noTrackmans": "No Trackman slots found",
    "trackmans": "Trackman",
    "trackmanHelp": "Help Trackman",
    "trackmanConfigs": "Trackman configuration",
    "makeTrackmanBooking": "Book trackman slot",
    "hometrackmans":"Book trackman slot",
    "noIndoors": "No Indoor slots found",
    "indoors": "Indoor",
    "indoorHelp": "Help Indoor",
    "indoorConfigs": "Indoor configuration",
    "makeIndoorBooking": "Book indoor slot",
    "homeindoors":"Book indoor slot",
    "countryCallingCode":"Country code",
    "getMobileCode":"Get code",
    "sentMobileCode":"Your verification code has been sent and will expire in 5 minutes.",
    "setNewEmail":"set new email",
    "setNewEmailDone":"Email has been set successfully. Please check your email inbox to activate the account.",
    "mobileCondeSendOK":"The code was successfully sent to your mobile.",
    "mobileCondeSendError": "An error occurred while sending the code",
    "setEmailByMobileCodeOK":"Email was set successfully",
    "setEmailByMobileCodeError": "An error occurred while setting email",
    "other":"Other",
    "alllowSearch":"I can be found in the search function (friends list, start time booking, tournament)",
    "privacyInfoscreen":"Show Infoscreen",
    "wantToDeleteScoreCard":"Do you want to clear the score card?",
    "wantToCleanScoreCard": "Do you want to delete all scores?",
    "createNewRound": "Start new round",
    "saved":"saved",
    "date":"Date",
    "year":"Year",
    "savedScoreCard":"Saved scorecards",
    "updateAppToUSe": "Please update the app to continue using it.",
    "appUpdating": "App is being updated, please wait a moment ...",
    "aboutApp": "About App",
    "updateApp": "Update",
    "mobileNoZero": "Mobile number WITHOUT leading 0",
    "ballausgabe": "Select get balls",
    "ballausgabeputting": "Get balls/Putting Area",
    "cardnumber": "Card number",
    "checkin": "Check-in",
    "dooropen": "Open door",
    "turnon": "Turn on",
    "units": "Units",
    "courseInfoToday": "Course information today",
    "digitalScorecard": "Tournaments digital scorecard",
    "digitalLivescoring": "Tournaments Livescoring",
    "saveAndGo": "Save and go",
    "saveScoreError": "Not all scores are entered",
    "courseStart": "Start",
    "courseEnd": "Last unit",
    "refereeText": "Do referee call?",
    "newFlight": "The flight has changed. Please save the score again!",
    "tutorial": "Tutorials",
    "tutorialTeetime": "Book Teetimes",
    "tutorialTournaments": "Manage Tournaments",
    "tutorialPros": "Book coaching lessons and courses",
    "tutorialPersonal": "My Card / Profile / News",
    "tutorialScoring": "Digital Scoring Tournaments",
    "tutorialUseReader": "Card Reader Operation",
    "payLastschrift": "Pay by direct debit",
    "payLastschriftHint": "The direct debit authorisation is deposited in the club. The amount due will be debited from the deposited account.",
    "addCreditLastschrift": "Top up credit",
    "allow4USearch": "I want to be found in the people search",
    "newsperson": "My News",
    "sunsetInfoToday": "Sunset today",
    "sunsetInfo": "Sunset",
    "sunsetBooking": "Teetime time could last until sunset",
    "friendsHelp": "Friends Help",
    "friendsAccepted": "Friend request accept/accepted",
    "friendsNotYetAcceppted": "Friend request not yet accepted",
    "friendsHelpDeclined": "Friend request reject/rejected",
    "friendsDelete": "Delete Friend",
    "friendsFemale": "Female",
    "friendsMale": "Male",
    "friendsAdd": "Add friend group or friend",
    "friendsOpenDetail": "Open Details",
    "friendsCloseDetail": "Close Details",
    "RoundFinished": "Round finished",
    "allowSingleEntryTeamError": "Single entry in team play is not allowed!",
    "clearScores": "Delete all scores",
    "time": "Time",
    "useLandscape": "Please use landscape mode on your mobile device!",
    "general": "General",
    "bills": "Bills",
    "teetime": "Teetime booking",
    "deleteNews": "Delete Message",
    "deleteThisNews": "Delete this message?",
    "aboname": "Name",
    "aboInfo": "Subscriptions",
    "aboHistory": "Subscription History",
    "bookAndPay": "Book and Pay",
    "noPaymentMethod": "Um Guthaben aufzuladen, gegebenfalls den Lastschrifteinzug im Club hinterlegen",
    "noPaymentMethod": "To top up credit, deposit direct debit in the club if necessary",
    "rounds": "Rounds",
    "digitalScorecardLegend1": "Transmits and saves the score",
    "digitalScorecardLegend2": "No score entered or changed",
    "digitalScorecardLegend3": "Score is changed but not yet transmitted and saved",
    "digitalScorecardLegend4": "Score has been successfully transferred and saved",
    "enterEmail": "Please enter the stored email and request the email again",
    "infoScreenTitle": "Infoscreen Display",
    "infoScreenMessage": "Please select Yes to be displayed at the Outdoor Terminal (Infoscreen). This setting can be changed again under Profile!",
    "addRPR": "Register Private Round",
    "syncHCPI": "Check the current HCPI™ ...",
    "noRPR": "Query of the current HCPI™ is not possible. No RP round can be registered.",
    "searchMarker": "Search for counter",
    "addRPRDebit": "Create RP round and pay with direct debit",
    "addRPRRound": "Create RP round",
    "saveRPRRound" : "The RP round was successfully saved and transferred",
    "saveRPRError" : "An error occurred while saving the RP round",
    "notAllScoresSaved" : "Signature visible when all scores are saved",
    "scorecardSaved" : "Scorecard was successfully saved",
    "GuestsQRCode" : "QRCodes for digital scorecard",
    "addLock" : "Add lock",
    "addLockSuccess" : "Lock successfully added",
    "setGolfChainHeader" : "Attention! Activate golf chain membership.",
    "setGolfChainText" : "Do you really want to activate the golf chain membership?",
    "noBlanks" : "Without spaces or special characters",
    "tendigits" : "10 digits",
    "eightdigits" : "8 digits",
    "ninedigits" : "9 digits",
    "golfChain" : 'I am a Golf Chain member',
    "ask9holes" : "Play 18 or 9 holes?",
    "ask9holesText" : "Please indicate how many holes you plan to play!",
    "9holes" : "9 holes",
    "18holes" : "18 holes",
    "pointPackages" : "Point packages",
    "points" : "points",
    "buypoints" : "Buy points",
    "for" : "for",
    "voucher" : "Vouchers",
    "recipient" : "Recipient",
    "addRecipient" : "Enter recipient",
    "howtoget" : "How would you like to receive your voucher?",
    "post" : "Post*",
    "addAmount" : "Enter amount",
    "addEmail" : "Enter email address",
    "zip" : "Postal code",
    "street2" : "Street2",
    "buyVoucher" : "Buy voucher",
    "addVoucherSuccessful" : "Voucher successfully purchased",
    "addVoucherNotSuccessful": "An error occurred when purchasing the voucher.",
    "directPayment": "Payment",

    // screens
    "pl0": 'Single',
    "pl1": 'Aggregate foursome', 
    "pl2": 'Foursome', 
    "pl3": 'Foursome', 
    "pl4": 'Chapman foursome', 
    "pl5": 'Ryesome foursome', 
    "pl6": 'Fourball', 
    "pl7": 'Fourball bestball', 
    "pl8": 'Scramble', 
    "pl9": 'Scramble 2s', 
    "pl10": 'Texas scramble', 
    "pl11": 'Florida scramble', 
    "pl12": 'RPR',
    "sm0": 'Strokeplay',
    "sm1": 'Stableford',
    "sm2": 'Against par',
    "sm3": 'Matchplay',
    "sm4": 'Strokeplay/Stableford',
    "sm5": 'Mixed',
    "sm6": '',
    "sm7": 'Free',
    "sm8": 'Strokeplay max score',
    "startlist": "Draw",
    "round": "Round",
    "time": "TIME",
    "flight": "GROUP",
    "today": "TODAY",
    "hole": "HOLE",
    "playerName" : 'Player name',

    // lnguages
    "de": "German",
    "en": "English",
    "it": "Italian",
    "fr": "French",
    "cz": "Czech",
    "cn": "Chinese",
    "nl": "Dutch",
    "es": "Spanish",

    // Errors
    "ls0001": "Your bank details are not complete, please contact your golf club",
    "ls0002": "Direct debit payment has been deactivated by the golf club",
    "dfdae65b": "No user found with the given email address",
    "71b72952": "Wrong password",
    "71b72951": "Missing email address. Please contact the club!",
    "71b72953": "Token error",
    "71b72954": "Token expired",
    "71b72955": "Invalid password",
    "df6b0128": "A player from out of the group is not allowed to book",
    "df6b0129": "Not logged in",
    "df6b0130": "User not found",
    "df6b0131": "Teetime is locked",
    "df6b0132": "Teetime not found",
    "df6b0133": "Booking in the past",
    "df6b0134": "Booking has no config",
    "df6b0135": "Booking cannot be deleted",
    "df6b0136": "No slots available",
    "df6b0137": "Course not found",
    "df6b0138": "Registration not allowed",
    "df6b0139": "Unregister not allowed",
    "df6b0140": "Calendar not found",
    "df6b0141": "Endtime reached",
    "df6b0142": "Booking preceding time reached",
    "df6b0143": "Slot not available",
    "df6b0144": "Time is in the past",
    "df6b0145": "Max days reached",
    "df6b0146": "Deletion period reached",
    "df6b0147": "Tournament not found",
    "df6b0148": "Pro not found",
    "df6b0149": "Range not found",
    "df6b0150": "Teetime payed online",
    "df6b0151": "Range payed online",
    "df6b0152": "Pro payed online",
    "df6b0153": "Cart not available",
    "df6b0154": "User in 4U locked",
    "19e28470": "User already exists",
    "7e341661": "Internal Error",
    "7e341662": "Login Error",
    "bfe57d09": "No user with this login found",
    "bfe57d10": "No email for user",
    "b4d05e56": "Something went wrong while uploading the file",
    "b4d05e57": "Something went wrong while deleting the file",
    "b4d05e58": "Something went wrong while saving data",
    "b4d05e59": "Something went wrong while getting data",
    "b4d05e60": "The idcard number is already registered. Please use this number to login",
    "b4d05e61": "The selected country/club was not found",
    "b4d05e62": "A person with the provided data already exists in the system. Login with this user id: #",
    "b4d05e63": "The person was not found. Please check the given data with the onces on the idcard (name, last name)",
  }
}