module.exports = {
  "translation": {
    "clubinone": "CLUBINONE 4U",
    "home": "Mein Club",
    "pros": "Pros",
    "persons": "Personen",
    "competitions": "Turniere",
    "competition": "Turnier",
    "competitionUC": "Turnier",
    "bookings": "Buchungen",
    "statistics": "Statistiken",
    "settings": "Einstellungen",
    "profile": "Profil",
    "login": "Einloggen",
    "logout": "Ausloggen",
    "organizations-list": "Meine Organisationen",
    "companies": "Clubs",
    "company": "Club",
    "searchcompanies": "Club suchen",
    "searchresult": "Suchergebnis",
    "history": "Kürzlich",
    "register": "Registrieren",
    "save": "Speichern",
    "userData": "Benutzerdaten",
    "changePassword": "Kennwort ändern",
    "changeEmail": "Email ändern",
    "language": "Sprache",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "birthdate": "Geburtstag",
    "password": "Kennwort",
    "email": "Email",
    "profilePicture": "Profilbild",
    "takePicture": "Bild auswählen",
    "upload": "Bild speichern",
    "passwordReset": "Kennwort ändern Email senden",
    "events": "Veranstaltungen",
    "event": "Veranstaltung",
    "eventUC": "Veranstaltung",
    "loading": "Daten werden geladen ...",
    "newcompanies": "Betreiber werden",
    "erroLogin": "Bitte melde dich für diesen Vorgang an!",
    "newCategory": "Neue Kategorie",
    "description": "Beschreibung",
    "changeSuccessful": "Änderung erfolgreich durchgeführt",
    "changeNotSuccessful": "Bei der Änderung ist ein Fehler aufgetreten",
    "deleteSuccessful": "Löschen erfolgreich durchgeführt",
    "deleteNotSuccessful": "Beim Löschen ist ein Fehler aufgetreten",
    "addSuccessful": "Anlegen erfolgreich durchgeführt",
    "addNotSuccessful": "Beim Anlegen ist ein Fehler aufgetreten",
    "notSuccessful": "Es ist ein Fehler aufgetreten",
    "name": "Name",
    "shortName": "Name kurz",
    "priority": "Reihenfolge",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "ok": "Ok",
    "bookingDuration": "Buchungsdauer",
    "showAvailableSlots": "Verfügbare Slots anzeigen",
    "showBookedSlots": "Gebuchte Slots anzeigen",
    "withSlider": "Slider anzeigen",
    "intervallBetweenBookings": "Zeitraum zwischen zwei Buchungen",
    "maxBookingsInFuture": "Max. Buchungen",
    "maxBookingsPerDay": "Max. Buchungen pro Tag",
    "maxBookingsPerWeek": "Max. Buchungen pro Woche",
    "maxPersonsBookable": "Max. Anzahl buchbarer Slots",
    "minPersonsBookable": "Min. Anzahl buchbarer Slots",
    "newBookingType": "Neuer Buchungstyp",
    "from": "Zeitraum von",
    "to": "Zeitraum bis",
    "cart": "Warenkorb",
    "teetimes": "Startzeiten",
    "pro": "Trainer",
    "pros": "Trainer",
    "courses": "Kurse",
    "course": "Kurs",
    "ranges": "Rangeplätze",
    "available": "",
    "sperrungnoshow": "Sperrung",
    "showAvatar": "Bild in Buchungen anzeigen",
    "withDog": "Mit Hund",
    "with9Hole": "Plant 9 Löcher zu spielen",
    "withPlayWithMe": "Gerne zu mir hinzubuchen",
    "member": "Mitglied",
    "memberFemale": "Mitglied weiblich",
    "memberMale": "Mitglied männlich",
    "guest": "Gast",
    "guestFemale": "Gast weiblich",
    "guestMale": "Gast männlich",
    "displayOptions": "Anzeigeoptionen",
    "showName": "Namen anzeigen",
    "showHCP": "Handicap anzeigen",
    "showGender": "Geschlecht anzeigen",
    "all": "Alle",
    "min1": "Mind. 1 Platz frei",
    "min2": "Mind. 2 Plätze frei",
    "min3": "Mind. 3 Plätze frei",
    "min4": "Mind. 4 Plätze frei",
    "hcp": "Handicap",
    "syncHCP": "Handicap abgleichen",
    "hcpSyncOK": "Handicapabgleich erfolgreich",
    "hcpErrorSync": "Handicapabgleich fehlgeschlagen",
    "bookingTeetimeHelp": "Hilfe Startzeitbuchung",
    "myBooking": "Meine Buchung",
    "monday": "Mo",
    "tuesday": "Di",
    "wednesday": "Mi",
    "thursday": "Do",
    "friday": "Fr",
    "saturday": "Sa",
    "sunday": "So",
    "Monday": "Montag",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch",
    "Thursday": "Donnerstag",
    "Friday": "Freitag",
    "Saturday": "Samstag",
    "Sunday": "Sonntag",
    "makeBooking": "Startzeit buchen",
    "makeBookingAndPay": "Startzeit buchen und bezahlen",
    "makeProBooking": "Pro-Stunde buchen",
    "makeAndPayProBooking": "Pro-Stunde buchen und bezahlen",
    "makeRangeBooking": "Rangeplatz buchen",
    "makeAndPayRangeBooking": "Rangeplatz buchen und bezahlen",
    "maxDays": "Max. Tage buchbar",
    "timeSwitch": "Uhrzeit Freischaltung weitere Tag",
    "allowAlwaysBookingHours": "Stunden frei buchbar",
    "courseConfigs": "Anlagen-Konfiguration",
    "unlimited": "Keine Einschränkung",
    "myCourseData": "Meine Daten",
    "myFutureBookings": "Alle Buchungen",
    "myBookingToday": "Buchungen dieser Tag",
    "myBookingWeek": "Buchungen diese Woche",
    "bookingCourseHelp": "Hilfe Anlagen Konfiguration",
    "bookingCourseHelp1": "Diese Konfigurationen wurden vom Golfclub hinterlegt.",
    "openFriends": "Freunde konfigurieren",
    "editBooking": "Buchung bearbeiten",
    "@me": "Mich",
    "player": "Spieler",
    "username": "Ausweisnummer oder Nickname",
    "announcement" : "Ausschreibung",
    "participants" : "Teilnehmer",
    "of" : "von",
    "round" : "Runde",
    "guests": "Gäste",
    "webAvailable": "Im Web verfügbar",
    "PF0": "Einzel",
    "PF1": "Aggregat Vierer",
    "PF2": "Vierer",
    "PF3": "Vierer Auswahldrive",
    "PF4": "Chapman Vierer",
    "PF5": "Ryesome Vierer",
    "PF6": "Vierball",
    "PF7": "Vierball Bestball",
    "PF8": "Scramble",
    "PF9": "Scramble 2er",
    "PF10": "Scramble Texas",
    "PF11": "Scramble Florida",
    "PF12": "RPR",
    "SM0": "Zählspiel",
    "SM1": "Stableford",
    "SM2": "Gegen Par",
    "SM3": "Lochwettspiel",
    "SM4": "Zählspiel und Stableford",
    "SM5": "Gemischt",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Zählspiel Höchstergebnis",
    "W0": "18-Loch",
    "W1": "9-Loch",
    "HCPREL0": "Nicht HCPI™-Relevant",
    "HCPREL1": "HCPI™-Relevant",
    "HCPREL2": "Tiger Rabbit",
    "regPeriod": "Registrierungs-Zeitraum",
    "regPeriodStart": "Öffnung der Anmeldungen",
    "regPeriodEnd": "Meldeschluss",
    "hcpi": "HCPI™",
    "toStr": "bis",
    "timerRunning": "Solange wartet die Startzeit noch...",
    "timerStopped": "Deine Startzeit steht nun auch anderen Kunden zur Verfügung.",
    "teetimeLocked": "Startzeit ist blockiert",
    "tournamentRegisterError1": "Nicht eingeloggt",
    "tournamentRegisterError2": "Geschlecht nicht passend",
    "tournamentRegisterError3": "Handicap nicht passend",
    "tournamentRegisterError4": "Registrierungszeitraum nicht passend",
    "tournamentRegisterError5": "Teilnehmerliste ist voll",
    "tournamentRegisterError6": "Altersklasse nicht passend",
    "tournamentRegisterError7": "Für # angemeldet",
    "tournamentUnRegisterError1": "Nicht eingeloggt",
    "tournamentUnRegisterError2": "Abmelden nicht erlaubt",
    "tournamentUnRegisterError3": "Abmeldedatum überschritten",
    "tournamentUnRegisterError4": "Nicht bei # angemeldet",
    "tournamentUnRegisterError5": "Aufgrund online Bezahlung ist keine Abmeldung möglich",
    "helpCoursesTime": "Kurs Datum, Start und Ende",
    "helpCoursesEnter": "Für Kurs anmelden",
    "helpCoursesUnregister": "Vom Kurs abmelden",
    "helpCoursesSeries" : "Kursserie",
    "helpCourses": "Hilfe Kurse",
    "helpTournamentTime": "Turnier Datum, Start und Ende",
    "helpTournamentDown": "Details aufklappen",
    "helpTournamentUp": "Details schließen",
    "helpTournamentEnter": "Für Turnier anmelden",
    "helpTournamentEnterWaitlist": "Für Turnierwarteliste anmelden",
    "helpTournamentUnregister": "Vom Turnier abmelden",
    "helpTournamentGenderFemale": "Turnier für Frauen zugelassen",
    "helpTournamentGenderMale": "Turnier für Männer zugelassen",
    "helpTournamentML": "Meldeliste",
    "helpTournamentSL": "Startliste",
    "helpTournamentEL": "Ergebnisliste",
    "helpTournamentAnnouncment": "Auschreibung",
    "helpTournament": "Hilfe Turniere",
    "helpEventTime": "Veranstaltung Datum, Start und Ende",
    "helpEventDown": "Details aufklappen",
    "helpEventUp": "Details schließen",
    "helpEventEnter": "Für Veranstaltung anmelden",
    "helpEventEnterWaitlist": "Für Veranstaltungwarteliste anmelden",
    "helpEventUnregister": "Von Veranstaltung abmelden",
    "helpEventGenderFemale": "Veranstaltung für Frauen zugelassen",
    "helpEventGenderMale": "Veranstaltung für Männer zugelassen",
    "helpEventAnnouncment": "Beschreibung",
    "helpEvent": "Hilfe Veranstaltungen",
    "helpRoundSL": "Startliste",
    "unregister": "Abmelden von",
    "unregisterSuccessful": "Erfolgreich abgemeldet",
    "unregisterNotSuccessful": "Bei der Abmeldung ist ein Fehler aufgetreten",
    "registerSuccessful": "Erfolgreich angemeldet",
    "registerNotSuccessful": "Bei der Anmeldung ist ein Fehler aufgetreten",
    "none": "Keine Auswahl",
    "early": "Früh",
    "late": "Spät",
    "makeTournamentRegistration": "Anmelden",
    "makeTournamentRegistrationAndPay": "Anmelden und Bezahlen",
    "makeTournamentUnRegistration": "Abmelden",
    "comment": "Kommentar",
    "registerTournament": "Anmeldung",
    "unregisterTournament": "Abmeldung",
    "addAdditionalPlayers": "Zusätzliche Teamspieler",
    "addAdditionalFlightPlayers": "Zusätzliche Spieler",
    "searchPlayer": "Spieler suchen",
    "friends": "Freunde",
    "friendGroups": "Freundegruppen",
    "friendsSave": "Freunde speichern",
    "addAdditionalFriends": "Freunde hinzufügen",
    "friendsNotConfirmed": "Freundschaftsanfrage",
    "friendsDeclined": "Abgelehnte Freundschaftsanfragen",
    "noTournaments": "Keine Turniere gefunden",
    "noEvents": "Keine Veranstaltungen gefunden",
    "noTeetimeCourseFound": "Keine Startzeitbuchungskalender gefunden",
    "hole": "Loch",
    "given": "gegeben",
    "noPros": "Keine Pros gefunden",
    "noRanges": "Keine Rangeplätze gefunden",
    "nextFreeSlot": "Heute nächster freier Platz",
    "booked": "gebucht",
    "precedingHours": "Stunden innerhalb dem ein Slot nicht mehr buchbar ist",
    "precedingStornoMinutes": "Minuten bis Buchung noch löschbar ist",
    "proHelp": "Hilfe Pro",
    "proConfigs": "Pro Konfigurationen",
    "rangeHelp": "Hilfe Range",
    "rangeConfigs": "Range Konfigurationen",
    "noCourses": "Keine Kurse gefunden",
    "coursename": "Platz",
    "placename": "Ort",
    "priceinfo": "Preisinfo",
    "onlinecomments": "Kommentar",
    "livescorings": "Digitales Scoring",
    "noLivescorings": "Für heute keine Turnier gefunden",
    "noEmail": "Es ist keine E-Mailadresse vorhanden. Bitte den Club kontaktieren damit Ihre E-Mailadresse hinterlegt wird.",
    "noEmailSMSLogIn": "Es ist keine E-Mailadresse vorhanden. Sie können selbst Ihre E-mail via Handynummer einstellen oder den Club kontaktieren damit Ihre E-Mailadresse hinterlegt wird.",
    "notActivated": "Das Konto ist noch nicht aktiviert. Bitte auf Aktivierungslink senden klicken, um das Konto zu aktivieren. Ist die E-Mailadresse nicht mehr aktuell? Dann bitte dem Golfclub die aktuelle E-Mailadresse mitteilen und den Vorgang wiederholen.",
    "sendActivationLink": "Aktivierungslink senden",
    "emailSendOK": "E-Mail wurde erfolgreich versendet",
    "emailSendError": "Beim E-Mail versenden ist ein Fehler aufgetreten",
    "verify": "Konto aktivieren",
    "verifyEmailOK": "Das Konto wurde erfolgreich aktiviert.",
    "verifyEmailError": "Beim Aktivieren des Kontos ist ein Fehler aufgetreten",
    "reset": "Neues Kennwort vergeben",
    "resetPassword": "Kennwort ändern",
    "passwordConfirm": "Kennwort bestätigen",
    "password8Chars": "Mindestens 8 Zeichen lang",
    "password1UpperCase": "Mindestens 1 Großbuchstabe",
    "password1Number": "Mindestens eine Zahl",
    "password1SpecialChar": "Mindestens ein Sonderzeichen (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Kennwörter stimmen überein",
    "resetPasswordOK": "Kenwort erfolgreich geändert",
    "resetPasswordError": "Fehler beim Ändern des Kennworts",
    "passwordRules": "Mind. 8 Zeichen lang, ein Großbuchstabe, eine Zahl und ein Sonderzeichen (-, +, _, !, @, #, $, %, ^, &, *, .)",
    "bookingDeleted": "Die Buchung wurde gelöscht",
    "bookingAdded": "Die Buchung wurde hinzugefügt",
    "dgvnumber": "Ausweisnummer (ausschließlich für Mitglieder von Golfverbänden)",
    "dgvdescription": "Jetzt ganz einfach und schnell registrieren!",
    "dgvdescription1": "DGV, Swiss Golf, ÖGV Mitglied",
    "dgvdescription2": "Dann bitte die jeweilige Karte auswählen und die Ausweisnummer, sowie die persönlichen Daten eintragen.",
    "searchCountry": "Land/Club suchen",
    "gender": "Geschlecht",
    "male": "Männlich",
    "female": "Weiblich",
    "title": "Titel",
    "activationLinkSent": "Es wurde ein Aktivierungslink per E-Mail versendet. Bitte Posteingang überprüfen und den Account aktivieren. Die E-Mail kann auch im Spam-Order gelandet sein. Ihr Benutzerlogin für den Login lautet: #",
    "cardclub": "Karte",
    "May": "Mai",
    "Dec": "Dez",
    "marker": "Zähler",
    "clear": "Löschen",
    "confirm": "Bestätigen",
    "signaturePlayer": "Unterschrift Spieler",
    "signatureMarker": "Unterschrift Zähler",
    "read": "Vorlesen",
    "gentleman": "Herren",
    "ladies": "Damen",
    "missingScores": "Es fehlen Ergebnisse in der Scorekarte. Bestätigen der Unterschrift nicht möglich!",
    "hometeetimes":"Startzeit buchen",
    "homefriends":"Meine Freunde",
    "homecard":"Meine Funkkarten",
    "homepros":"Pro buchen",
    "homecourses":"Kurs buchen",
    "homeranges":"Rangeplatz buchen",
    "homecompetitions":"Turnierkalender",
    "homelivescorings":"Scorekarte in der App",
    "homeevents":"Veranstaltungskalender",
    "homeMemberdata":"Meine Daten",
    "homePayment":"In App Bezahlung",
    "homeMember":"Bereits Mitglied oder Gast? Dann direkt nach dem Login alle Online Services nutzen.",
    "homeEmail":"Email Adresse im Club hinterlegt?",
    "homeNewCustomer":"Jetzt als neuer Gast registrieren und alle Online Services nutzen.",
    "myclub":"Meine Daten",
    "personInfo": "Persönliche Informationen",
    "clubInfo": "Club Informationen",
    "addressInfo": "Adress Informationen",
    "bankInfo": "Bank Informationen",
    "authorizePayments": "Zahlungen autorisieren",
    "revokePayments": "Autorisierung löschen",
    "paypalPayment": "Paypal Zahlung",
    "paypalAuthorized":"Sie haben Paypalzahlung erfolgreich autorisiert",
    "creditCardPayment": "Kreditkarten Zahlung",
    "cardholderName": "Name des Karteninhabers",
    "creditCardNumber": "Kreditkartennummer",
    "expiration": "Ablauf",
    "accept": "Kreditkarte hinzufügen",
    "creditCardAuthorized":"Sie haben Kreditkartenzahlung erfolgreich autorisiert",
    "creditCardAuthorizedFailed":"Autorisierung fehlgeschlagen",
    "noPaymentMethod": "Es ist noch keine Bezahlmethode hinterlegt. Einfach im Profil Paypal oder eine Kreditkarte hinterlegen.",
    "paymentMethodDeleted": "Die Bezahlmethode wurde gelöscht",
    "paymentMethodCreated": "Die Bezahlmethode wurde erfolgreich hinterlegt",
    "startdate": "Eintritt",
    "contractstart": "Vertragsbeginn",
    "contractend": "Vertragsende",
    "enddate": "Austritt",
    "membership": "Mitgliedschaft",
    "contract": "Vertrag",
    "homeclubnum": "Heimatclubnummer",
    "street": "Straße",
    "city": "Ort",
    "telephone": "Telefon",
    "mobile": "Mobil",
    "newsletter": "Newsletter",
    "allowNewsletter": "Abonniert",
    "notAllowNewsletter": "Nicht abonniert",
    "emailbill": "Rechnung per Email",
    "allowBillEmail": "Abonniert",
    "notAllowBillEmail": "Nicht abonniert",
    "accountowner": "Kontoinhaber",
    "bank": "Bank",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Lastschrifteinzug",
    "debitsOk": "Erlaubt",
    "debitsNotOk": "Nicht erlaubt",
    "refNumber": "Mandatsreferenznummer",
    "homeclub": "Heimatclub",
    "credit": "Guthaben",
    "addFriendGroup": "Freundegruppe hinzufügen",
    "addCredit": "Guthaben aufladen",
    "amount": "Betrag",
    "recharge": "Aufladen",
    "automaticRechargeBeyond": "Automatisch Aufladen wenn Guthaben diesen Betrag unterschreitet",
    "paymenttype": "Bezahloption",
    "rechargeBy": "Erhöhen um Betrag",
    "amountToPay": "Zu zahlender Betrag",
    "freeSlots": "Freie Plätze",
    "addAdditionalFlightFriends": "Zusätzliche Freunde",
    "card": "Meine Karte",
    "assignedCards": "Zugeordnete Karten",
    "valid": "Gültigkeit",
    "noCardFound": "Kein Karte gefunden",
    "permissions": "Berechtigungen",
    "status": "Status",
    "locked": "Gesperrt",
    "active": "Aktiv",
    "cardHistory": "Kartenprotokoll",
    "noHistory" : "Keine Daten vorhanden",
    "clubSelection": "Zur Clubauswahl",
    "bonuspoints": "Sonderpunkte",
    "creditoncard": "Guthaben auf der Karte",
    "pointsoncard": "Punkte auf der Karte",
    "getBalls": "Bälle ausgeben anfordern",
    "getPutting": "# öffnen anfordern",
    "getCheckIn": "Check-In anfordern",
    "getDoor": "Türe anfordern",
    "pressButton": "Den silbernen Knopf am Ballautomaten drücken",
    "ballmachineTimerRunning": "Den silbernen Knopf am Ballautomaten drücken innerhalb der nächsten ",
    "checkInTimerRunning": "Den silbernen Knopf am CheckIn drücken innerhalb der nächsten ",
    "doorTimerRunning": "Den silbernen Knopf an der Türe drücken innerhalb der nächsten ",
    "bookingTimerRunning": " Den silbernen Knopf an dem Leser drücken innerhalb der nächsten ",
    "seconds": "Sekunden",
    "appButtonError": "Bei der Anforderung ist ein Fehler aufgetreten. Bitte in ein paar Sekunden noch einmal probieren.",
    "r0000001": "Fehler für diese Transaktion aufgetreten",
    "r0000002": "Bezahlung wurde erstellt. Bitte den silbernen Knopf drücken.",
    "r0000003": "Der Leser wird von einer anderen Person benutzt. Bitte einen Moment warten",
    "r0000004": "Die DGV Nummer wurde schon benutzt",
    "r0000005": "Leser wurde nicht gefunden",
    "frequency0": "",
    "frequency1": "Monatlich",
    "frequency2": "Quartal",
    "frequency3": "Halbjährlich",
    "frequency4": "Jährlich",
    "frequency5": "Täglich",
    "packageAvailable": "verfügbar",
    "generalPackage": "Ballkorb Pakete",
    "personalPackage": "Persönliches Ballkorb Pakete",
    "noRPRs": "Für heute keine registrierten privaten Runden gefunden",
    "RPRs": "Registrierte private Runden (max. 2 pro Tag)",
    "RPR": "Registrierte private Runde",
    "getBooking": "Einschalten anfordern",
    "news": "Golfclub-News",
    "imprint": "Impressum",
    "alreadyInFlight": " ist bereits in der Spielgruppe",
    "acceptAgb": "Ich akzeptiere die allgemeinen Geschäftsbedingungen",
    "acceptDSGVO": "Ich akzeptiere die Datenschutzbestimmungen",
    "agb": "Allgemeinen Geschäftsbedingungen",
    "datalegal": "Datenschutzbestimmungen",
    "marshalls": "Marshall",
    "send": "Senden",
    "messageToOffice": "Nachricht an Sekreteriat",
    "messageSent": "Nachricht verschickt",
    "statusSet": "Status wurde gesetzt",
    "holes": "Löcher",
    "tee": "Abschlag",
    "personalScorecard": "Persönliche Scorekarte",
    "getScorecard": "Zur Scorekarte",
    "length": "Meter",
    "faq": "FAQs",
    "yes": "Ja",
    "no": "Nein",
    "checkIn": "Einchecken",
    "checkInText": "Sollen die heutigen Startzeiten auf anwesend gesetzt werden?",
    "DSGVO": "Datenschutzbestimmungen",
    "Agb": "Allgemeinen Geschäftsbedingungen",
    "noNews": "Keine Neuigkeiten vorhanden",
    "isInclusion": "Behindertengerecht",
    "ranking": "Rangliste",
    "noPlayers": "Es sind keine Spieler in der Gruppe",
    "addPerson": "Spieler hinzufügen",
    "functions": "Die unten aufgeführten digitalen Golferlebnisse stellt Ihnen dieser Golfclub zur Verfügung!",
    "wantToLogout": "Ich möchte mich ausloggen.",
    "canLogin": "Jetzt mit dem vergebenen Kennwort und dieser 10-stelligen Nummer einloggen: ",
    "registerGuest": "Registrierung für Gäste",
    "nickname": "Nickname",
    "contactClub": "Zum Ändern von Daten bitte den Club kontaktieren.",
    "today": "Heute",
    "hcpiInputHint": "HCPI™ als Zahl eingeben. Als Trennzeichen für die Kommastelle einen Punkt verwenden.",
    "noInternet": "Es sind nicht alle Ergebnisse übertragen. Bitte die Internetverbindung prüfen!",
    "closestClub": "Der nächstgelegene Club",
    "mobileScreen1": "Digitale Golferlebnisse in Ihrem Golfclub",
    "mobileScreen2": "Rangebuchung",
    "mobileScreen3": "Pros & Kurse",
    "mobileScreen4": "Karten verwalten",
    "mobileScreen5": "Ballguthaben aufladen",
    "deleteAccount": "Account löschen",
    "wantToDeleteAccount": "Den Account löschen?",
    "noWellnesses": "Keine Wellnessplätze gefunden",
    "wellnesses": "Wellnessplätze",
    "wellnessHelp": "Hilfe Wellness",
    "wellnessConfigs": "Wellness Konfigurationen",
    "makeWellnessBooking": "Wellnessplatz buchen",
    "homewellnesses":"Wellnessplatz buchen",
    "noTrackmans": "Keine Trackmanplätze gefunden",
    "trackmans": "Trackmanplätze",
    "trackmanHelp": "Hilfe Trackman",
    "trackmanConfigs": "Trackman Konfigurationen",
    "makeTrackmanBooking": "Trackmanplatz buchen",
    "hometrackmans":"Trackmanplatz buchen",
    "noIndoors": "Keine Indoorplätze gefunden",
    "indoors": "Indoorplätze",
    "indoorHelp": "Hilfe Indoor",
    "indoorConfigs": "Indoor Konfigurationen",
    "makeIndoorBooking": "Indoorplatz buchen",
    "homeindoors":"Indoorplatz buchen",
    "countryCallingCode":"Land Vorwahlnummer",
    "getMobileCode":"Code abrufen",
    "sentMobileCode":"Ihr Verifizierungscode wurde gesendet und läuft in 5 Minuten ab.",
    "setNewEmail":"neue E-Mail einstellen",
    "setNewEmailDone":"E-Mail wurde erfolgreich eingestellt. Bitte überprüfen Sie Ihr E-Mail-Postfach, um das Konto zu aktivieren.",
    "mobileCondeSendOK":"Der Code wurde erfolgreich an Ihr Handy gesendet",
    "mobileCondeSendError": "Beim Handy Code versenden ist ein Fehler aufgetreten",
    "setEmailByMobileCodeOK":"E-Mail wurde erfolgreich eingestellt",
    "setEmailByMobileCodeError": "Beim E-mail einstellen ist ein Fehler aufgetreten",
    "other":"Andere",
    "alllowSearch":"Ich kann in der Suchfunktion (Freundesliste, Startzeitbuchung, Turnier) gefunden werden",
    "privacyInfoscreen":"Infoscreen anzeigen",
    "wantToDeleteScoreCard": "Möchten Sie die Scorekarte löschen?",
    "wantToCleanScoreCard": "Möchten Sie alle Scores löschen?",
    "createNewRound": "Neue Runde starten",
    "saved":"gespeichert",
    "date":"Datum",
    "year":"Jahr",
    "savedScoreCard":"Gespeicherte Scorekarten",
    "updateAppToUSe":"Bitte aktualisieren Sie die App, um sie weiter nutzen zu können.",
    "appUpdating":"App wird gerade aktualisiert, bitte warten Sie einen Moment ...",
    "aboutApp":"Über App",
    "updateApp":"Aktualisieren",
    "mobileNoZero": "Mobilnummer OHNE führende 0",
    "ballausgabe": "Ballausgabe auswählen",
    "ballausgabeputting": "Ballausgabe/Putting Area auswählen",
    "cardnumber": "Kartennummer",
    "checkin": "Einchecken",
    "dooropen": "Türe öffnen",
    "turnon": "Einschalten",
    "units": "Einheiten",
    "courseInfoToday": "Platzinformation heute",
    "digitalScorecard": "Turniere digitale Scorekarte",
    "digitalLivescoring": "Turniere Livescoring",
    "saveAndGo": "Speichern und weiter",
    "saveScoreError": "Es sind nicht alle Scores eingetragen",
    "courseStart": "Start",
    "courseEnd": "Letzte Einheit",
    "refereeText": "Referee call durchführen?",
    "newFlight": "Die Spielgruppe hat sich geändert. Bitte den Score erneut speichern!",
    "tutorial": "Tutorials",
    "tutorialTeetime": "Startzeiten buchen",
    "tutorialTournaments": "Turniere verwalten",
    "tutorialPros": "Trainerstunden und Kurse buchen",
    "tutorialPersonal": "Meine Karte / Profil / News",
    "tutorialScoring": "Digitales Scoring Turniere",
    "tutorialUseReader": "Bedienung der Kartenlesegeräte",
    "payLastschrift": "Mit Lastschrifteinzug bezahlen",
    "payLastschriftHint": "Im Club ist die Einzugsermächtigung hinterlegt. Der fällige Betrag wird von dem hinterlegten Konto abgebucht.",
    "addCreditLastschrift": "Guthaben aufladen",
    "allow4USearch": "Ich möchte bei der Personensuche gefunden werden",
    "newsperson": "Meine Nachrichten",
    "sunsetInfoToday": "Sonnenuntergang heute",
    "sunsetInfo": "Sonnenuntergang",
    "sunsetBooking": "Startzeit könnte bis Sonnenuntergang dauern",
    "friendsHelp": "Freunde Hilfe",
    "friendsAccepted": "Freundschaftsanfrage annehmen/angenommen",
    "friendsNotYetAcceppted": "Freundschaftsanfrage noch nicht angenommen",
    "friendsHelpDeclined": "Freundschaftsanfrage ablehnen/abgelehnt",
    "friendsDelete": "Freund löschen",
    "friendsFemale": "Weiblich",
    "friendsMale": "Männlich",
    "friendsAdd": "Hinzufügen Freundegruppe oder Freund",
    "friendsOpenDetail": "Details aufklappen",
    "friendsCloseDetail": "Details schließen",
    "RoundFinished": "Runde beendet",
    "allowSingleEntryTeamError": "Einzelanmeldung im Teamspiel ist nicht erlaubt!",
    "clearScores": "Alle Scores Löschen",
    "time": "Uhrzeit",
    "useLandscape": "Bitte verwenden Sie das Querformat auf Ihrem mobilen Gerät!",
    "tournamentRegulations": "Wettspielbedingungen akzeptieren",
    "general": "Allgemein",
    "bills": "Rechnungen",
    "teetime": "Startzeitbuchung",
    "deleteNews": "Nachricht löschen",
    "deleteThisNews": "Diese Nachricht löschen?",
    "aboname": "Name",
    "aboInfo": "Abos",
    "aboHistory": "Abo Historie",
    "playtype": "Spieltyp",
    "agegroup": "Altersgruppe",
    "intern": "Intern",
    "open": "Offen",
    "bookAndPay": "Buchen und Bezahlen",
    "noPaymentMethod": "Um Guthaben aufzuladen, gegebenfalls den Lastschrifteinzug im Club hinterlegen",
    "rounds": "Runden",
    "digitalScorecardLegend1": "Überträgt und speichert den Score",
    "digitalScorecardLegend2": "Kein Score eingegeben oder verändert",
    "digitalScorecardLegend3": "Score ist geändert aber noch nicht übertragen und gespeichert",
    "digitalScorecardLegend4": "Score ist erfolgreich übertragen und gespeichert",
    "enterEmail": "Bitte die hinterlegte Email eingeben und die Email erneut anfordern",
    "infoScreenTitel": "Infoscreen Anzeige",
    "infoScreenMessage": "Bitte Ja auswählen, um am Outdoor Terminal (Infoscreen) angezeigt zu werden. Diese Einstellung kann unter Profil wieder geändert werden!",
    "addRPR": "Registrierte private Runde anmelden",
    "syncHCPI": "Prüfe das aktuelle HCPI™ ...",
    "noRPR": "Die Abfrage des aktuellen HCPI™ ist nicht möglich. Es kann keine RP Runde angemeldet werden.",
    "searchMarker": "Zähler suchen",
    "addRPRLastschrift": "RP Runde anlegen und mit Lastschrift bezahlen",
    "addRPRRound": "RP Runde anlegen",
    "saveRPRRound" : "Die RP Runde wurde erfolgreich gespeichert und übertragen",
    "saveRPRError" : "Beim Speichern der RP Runde ist ein Fehler aufgetreten",
    "notAllScoresSaved" : "Unterschrift sichtbar, wenn alle Ergebnisse gespeichert sind",
    "scorecardSaved" : "Scorekarte wurde erfolgreich gespeichert",
    "GuestsQRCode" : "QRCodes für digitale Scorekarte",
    "addLock" : "Sperrung hinzufügen",
    "addLockSuccess" : "Sperrung erfolgreich hinzugefügt",
    "setGolfChainHeader" : "Achtung! Golfchain Zugehörigkeit aktivieren.",
    "setGolfChainText" : "Möchten sie wirklich die Golfchain Zugehörigkeit aktivieren?",
    "noBlanks" : "Ohne Leerzeichen oder Sonderzeichen",
    "tendigits" : "10-stellig",
    "eightdigits" : "8-stellig",
    "ninedigits" : "9-stellig",
    "golfChain" : 'Ich bin Golf Chain Mitglied',
    "ask9holes" : "18 oder 9 Löcher spielen?",
    "ask9holesText" : "Bitte angeben wie viele Löcher geplant sind zu spielen!",
    "9holes" : "9 Löcher",
    "18holes" : "18 Löcher",
    "pointPackages" : "Punktepakete",
    "points" : "Punkte",
    "buypoints" : "Punkte kaufen",
    "for" : "für",
    "voucher" : "Gutscheine",
    "recipient" : "Empfänger",
    "addRecipient" : "Empfänger eingeben",
    "howtoget" : "Wie möchten Sie Ihren Gutschein erhalten?",
    "post" : "Post*",
    "addAmount" : "Betrag eingeben",
    "addEmail" : "Email Adresse eingeben",
    "zip" : "PLZ",
    "street2" : "Straße2",
    "buyVoucher" : "Gutschein kaufen",
    "addVoucherSuccessful": "Gutschein erfolgreich erworben",
    "addVoucherNotSuccessful": "Beim Kauf des Gutscheins ist ein Fehler aufgetreten.",
    "directPayment": "Bezahlung",

    // screens
    "pl0": 'Einzel',
    "pl1": 'Aggregat Vierer', 
    "pl2": 'Vierer', 
    "pl3": 'Vierer Auswahldrive', 
    "pl4": 'Chapman Vierer', 
    "pl5": 'Ryesome Vierer', 
    "pl6": 'Vierball', 
    "pl7": 'Vierball Bestball', 
    "pl8": 'Scramble', 
    "pl9": 'Scramble 2er', 
    "pl10": 'Scramble Texas', 
    "pl11": 'Scramble Florida', 
    "pl12": 'RPR',
    "sm0": 'Zählspiel',
    "sm1": 'Stableford',
    "sm2": 'Gegen Par',
    "sm3": 'Lochspiel',
    "sm4": 'Zählspiel/Stableford',
    "sm5": 'Gemischt',
    "sm6": '',
    "sm7": 'Frei',
    "sm8": 'Zählspiel Maxscore',
    "startlist": "Startliste",
    "round": "Runde",
    "time": "ZEIT",
    "flight": "SPIELGRUPPE",
    "today": "HEUTE",
    "hole": "LOCH",
    "playerName" : 'Spieler Name',
    "authenticate" : 'Bitte authentifizieren',
    "usepasscode" : 'Bitte Kennwort verwenden',
    "biometriclogin" : 'Biometrie Login',
    "biometricloginsubtitle" : 'Biometrie Benutzen um sich anzumelden',
    "logoutsuccess" : 'Logout erfolgreich',
    "logoutnosuccess" : 'Logout nicht erfolgreich',

    "total": "Gesamt",
    "country": "Land",
    "noScoresFound": "Keine Scores für dieses Turnier gefunden.",
    "position": "Position",
    "thru": "Nach",
    "eagle": "Eagle oder besser",
    "birdie": "Birdie",
    "par": "Par",
    "bogey": "Bogey",
    "doublebogey": "Double Bogey",
    "triplebogey": "Triple Bogey oder schlechter ",

    // FAQ    
    "FAQ": "Haben Sie noch Fragen? Dann werfen Sie doch einen Blick auf unsere FAQs.",
    "faq0000001": "Was kann ich mit CLUB IN ONE - 4U machen?",
    "faq0000001-a": "Sie können damit Ihre Buchungen rund um dem Golfsport in Ihrem Golfclub durchführen. Weiter stehen Livescoring Funktionen für Turnier zur Verfügung. in IHREM Kundenportal erhalten sie Information über ihre hinterlegten Daten und werden vom Golfclub über aktuelle News informiert. Falls der Golfclub die CLUB IN ONE Reader für Ballautomaten oder Türen besitzt können diese per CLUB IN ONE 4U - App (i-Phone, Android) bedient werden.",
    "faq0000002": "Wo ist CLUB IN ONE - 4U verfügbar?",
    "faq0000002-a": "CLUB IN ONE - 4U ist auf den Golfplätzen verfügbar welche die Clubverwaltungs-Software CLUB IN ONE einsetzen.",
    "faq0000003": "Wie erkenne ich, ob CLUB IN ONE - 4U verfügbar ist?",
    "faq0000003-a": "Sie finden einen Link auf der Webseite des Golfclubs oder finden Clubs in der CLUB IN ONE 4U - App (iPhone und Android) in der Suchfunktion.",
    "faq0000004": "Wie kann ich CLUB IN ONE - 4U verwenden?",
    "faq0000004-a": "Haben sie im Club bereits eine EMail Adresse hinterlegt und sind Mitglied oder haben schon einen Gast-Login für die Startzeitbuchung ihres Clubs, können sie die 10-stellige Ausweisnummer zur Aktivierung bzw. dem Login verwenden. Natürlich können sie alle Kalender auch ohne Login einsehen.",
    
    // lnguages
    "de": "Deutsch",
    "en": "Englisch",
    "it": "Italienisch",
    "fr": "Französisch",
    "cz": "Tschechisch",
    "cn": "Chinesisch",
    "nl": "Niederländisch",
    "es": "Spanisch",

    // Errors
    "ls0001": "Ihre Bankdaten sind nicht vollständig, wenden Sie sich bitte an Ihren Golfclub.",
    "ls0002": "Lastschriftbezahlung wurde vom Golfclub deaktiviert.",
    "dfdae65b": "Kein Benutzer mit der Email-Adresse gefunden",
    "71b72952": "Kennwort falsch",
    "71b72951": "Email-Adresse nicht hinterlegt. Bitte wenden sie sich an den Club!",
    "71b72953": "Token fehlerhaft",
    "71b72954": "Token abgelaufen",
    "71b72955": "Ungültiges Kennwort",
    "df6b0128": "Einem Spieler aus der Gruppe ist es nicht erlaubt zu buchen",
    "df6b0129": "Nicht angemeldet",
    "df6b0130": "Benutzer nicht gefunden",
    "df6b0131": "Startzeit ist blockiert",
    "df6b0132": "Startzeit nicht gefunden",
    "df6b0133": "Buchung ist in der Vergangenheit",
    "df6b0134": "Buchung ist nicht konfiguriert",
    "df6b0135": "Buchung kann nicht gelöscht werden",
    "df6b0136": "Keine Slot verfügbar",
    "df6b0137": "Kurs nicht gefunden",
    "df6b0138": "Registrierung nicht erlaubt",
    "df6b0139": "Abmeldung nicht erlaubt",
    "df6b0140": "Kalender nicht gefunden",
    "df6b0141": "Endzeit ist erreicht",
    "df6b0142": "Buchung löschen Zeitfenster erreicht",
    "df6b0143": "Slot nicht verfügbar",
    "df6b0144": "Zeit ist in der Vergangenheit",
    "df6b0145": "Max. Anzahl Tage erreicht",
    "df6b0146": "Löschzeitraum erreicht",
    "df6b0147": "Turnier nicht gefunden",
    "df6b0148": "Pro nicht gefunden",
    "df6b0149": "Range nicht gefunden",
    "df6b0150": "Startzeit wurde online bezahlt",
    "df6b0151": "Range wurde online bezahlt",
    "df6b0152": "Pro wurde online bezahlt",
    "df6b0153": "Cart steht nicht zur Verfügung",
    "df6b0154": "Benutzer für 4U gesperrt",
    "19e28470": "Benutzer existiert schon",
    "7e341661": "Internal Error",
    "7e341662": "Login Fehler",
    "bfe57d09": "Kein Benutzer mit diesem Login gefunden",
    "bfe57d10": "Kein E-Mail für diesen Benutzer",
    "b4d05e56": "Fehler beim Datei Upload",
    "b4d05e57": "Fehler beim Datei löschen",
    "b4d05e58": "Fehler beim Daten speichern",
    "b4d05e59": "Fehler beim Daten selektieren",
    "b4d05e60": "Die Ausweisnummer ist schon registriert. Bitte mit dieser Nummer einloggen",
    "b4d05e61": "Das ausgewähle Land/Club wurde nicht gefunden",
    "b4d05e62": "Es gibt schon eine Person mit diesen Angaben im System. Melde dich mit diesem Benutzer an: #",
    "b4d05e63": "Die Person konnte nicht gefunden werden. Bitte überprüfe Deine Angaben mit denen auf dem Ausweis (Name, Nachname)",
  }
}